import { TabList, TabPanel, TabPanels } from "@reach/tabs"
import clsx from "clsx"
import React from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  ProductShowMakerFragment,
  ProductStatus,
  useProductShowQuery,
  useProductProfileTestingAndComplianceQuery,
  useProductProfilePricingAndLogisticsQuery,
  useActiveClientOrderQuery,
  ModeOfTransit,
  ProductEditMakerFragment,
} from "../generated/graphql"
import { formatDate } from "../util/dates"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
  BorderBoxItem,
} from "./BorderBox"
import EditLink, { TabEditLink } from "./EditLink"
import { EmptyValue, MaybeText } from "./EmptyValue"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { ReactComponent as DownChevronSVG } from "../images/down-chevron.svg"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import VisuallyHidden from "@reach/visually-hidden"
import UnitToggle from "./UnitToggle"
import UnitValue from "./UnitValue"
import UnitLabel from "./UnitLabel"
import CogsTableSettings from "./CogsTableSettings"
import { differenceInMonths } from "date-fns"
import { ImageWithControls } from "./LegacyImage"
import { StyledTab } from "./StyledTab"
import AddProductToClientOrderButton from "./AddProductToClientOrderButton"
import ProductOrderHistoryTable from "./ProductOrderHistoryTable"
import CarbonOffsets from "./CarbonOffsets"
import { ProductAttributes } from "./ProductAttributes"
import Tabs from "./Tabs"
import numberWithCommas from "../util/number-with-commas"
import { ReactComponent as Lightbulb } from "../images/lightbulb.svg"
import { ReactComponent as DrivingEmissons } from "../images/driving-emissions.svg"
import { ReactComponent as DrinkingWater } from "../images/drinking-water.svg"
import { ReactComponent as Pesticides } from "../images/pesticides.svg"
import { ReactComponent as Recycled } from "../images/recycled.svg"

const ProductProfile = (props: { id: string }) => {
  const result = useProductShowQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const product = result.data.product

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-lg-row">
        <div className="mr-auto mb-2">
          <Header product={product} />
        </div>
        <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
          <Status product={product} />
        </div>
      </div>
      <div className="pt-3 pb-4">
        <SubHeader product={product} />
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-4 mb-4">
          <div className="card border-light-teal">
            <div className="card-body">
              <LeftCard product={product} />
            </div>
          </div>
        </div>
        <div className="col col-md-12 col-lg-8">
          <div className="card border-light-teal">
            <div className="card-body">
              <RightCard product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ProductProps = {
  product: ProductShowMakerFragment
}

const productStatusLabels: Readonly<Record<ProductStatus, string>> = {
  DRAFT: "Draft",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
}

const AddToOrderButton = ({ product }: ProductProps) => {
  const result = useActiveClientOrderQuery()

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { activeClientOrder } = result.data

  if (!activeClientOrder) return null

  return (
    <AddProductToClientOrderButton
      clientOrder={activeClientOrder.clientOrder}
      product={product}
      className="btn btn-primary mb-2"
    />
  )
}

const Header = ({ product }: ProductProps) => (
  <div className="mt-4 d-flex flex-row">
    <div className="mr-30">
      {product.image && product.image.imgixUrl && (
        <Imgix src={product.image.imgixUrl} sizes="100px" className="shadow" />
      )}
    </div>

    <div className="d-flex flex-column">
      <h1
        className="mb-3"
        style={{
          fontSize: 40,
          lineHeight: `48px`,
          letterSpacing: "0.05em",
        }}
      >
        {product.name}
      </h1>
    </div>
  </div>
)

const Status = ({ product }: ProductProps) => (
  <>
    <AddToOrderButton product={product} />
    <div className="mb-2">
      <div>Last Updated: {formatDate(product.updatedAt)}</div>
    </div>
    <div
      className="btn-group btn-group-sm"
      role="group"
      aria-label="Product Status"
    >
      {Object.values(ProductStatus).map((status) => (
        <a
          key={status}
          href={`${product.editUrl}#product-status-field`}
          className={clsx(
            "btn btn-sm",
            product.status === status
              ? "btn-teal text-white"
              : "btn-outline-teal",
          )}
        >
          {productStatusLabels[status]}
        </a>
      ))}
    </div>
  </>
)

const SubHeader = ({ product }: ProductProps) => (
  <table>
    <tbody>
      <tr>
        <td className="font-weight-bold pr-4">Product Categories</td>
        {product.productCategories.length > 0 ? (
          product.productCategories.map((productCategory, i) => (
            <td key={i} className="pr-4">
              {productCategory.name}
            </td>
          ))
        ) : (
          <td>
            <EmptyValue variant="message" />
          </td>
        )}
      </tr>
      <tr>
        <td className="font-weight-bold pr-4">Product Group</td>
        <td>
          <a href={product.productGroup.showUrl} className="text-dark-teal">
            {product.productGroup.name}
          </a>
        </td>
      </tr>
      <tr>
        <td className="font-weight-bold pr-4">Ships From</td>
        <td>
          <MaybeText
            value={
              product.shipsFromCountry ? product.shipsFromCountry.name : null
            }
          />
        </td>
      </tr>
      <tr>
        <td className="font-weight-bold pr-4">Made in Country (COO)</td>
        <td>
          <MaybeText
            value={
              product.countryOfOrigin ? product.countryOfOrigin.name : null
            }
          />
        </td>
      </tr>
    </tbody>
  </table>
)

const LeftCard = ({ product }: ProductProps) => (
  <>
    <div className="d-flex mb-4">
      <h5 className="flex-grow-1">{product.maker.name}</h5>
      <div className="pl-2 ml-auto">
        <EditLink {...product} />
      </div>
    </div>

    <p>
      Maker Country:{" "}
      {product.makerCountry ? (
        product.makerCountry.name
      ) : (
        <EmptyValue variant="message" />
      )}
    </p>

    <p>
      Maker Facing Name: {product.makerName || <EmptyValue variant="message" />}
    </p>

    {/* TODO(AM)
    <p>
      Production Delay:
      {}
    </p>
    */}

    <BorderBoxContainer>
      <BorderBoxHeading>Shipping Ports</BorderBoxHeading>
      {product.maker.shippingPorts.nodes.length > 0 ? (
        <BorderBox>
          {product.maker.shippingPorts.nodes.map(({ port }) => (
            <BorderBoxItem key={port}>{port}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>No Maker Ports have been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Types</BorderBoxHeading>
      {product.makerTypes.length > 0 ? (
        <BorderBox>
          {product.makerTypes.map((makerType) => (
            <BorderBoxItem key={makerType}>{makerType}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>No Maker Types have been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Attributes</BorderBoxHeading>
      {product.selectedMakerAttributes.length > 0 ? (
        <BorderBox>
          {product.selectedMakerAttributes.map((value) => (
            <BorderBoxItem key={value.id}>{value.text}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Value Alignments have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Environments</BorderBoxHeading>
      {product.environments.length > 0 ? (
        <BorderBox>
          {product.environments.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Environments have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Capabilities</BorderBoxHeading>
      {product.makerCapabilities.length > 0 ? (
        <BorderBox>
          {product.makerCapabilities.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Capabilities have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Certifications</BorderBoxHeading>
      {product.makerCertificationTypes.length > 0 ? (
        <BorderBox>
          {product.makerCertificationTypes.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Certifications have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>
  </>
)

const RightCard = (props: ProductProps) => {
  const tabIds = [
    "#main",
    "#pricing-and-logistics",
    "#history",
    "#compliance",
    "#impact",
  ]

  return (
    <div className="position-relative">
      <Tabs tabIds={tabIds}>
        <div className="d-flex flex-grow-1 justify-content-center text-center mx-4">
          <TabList>
            <StyledTab>Main</StyledTab>
            <StyledTab>Pricing & Logistics</StyledTab>
            <StyledTab>History</StyledTab>
            <StyledTab>Compliance</StyledTab>
            <StyledTab>Impact</StyledTab>
          </TabList>
        </div>
        <TabPanels>
          <TabPanel>
            <MainPanel {...props} />
          </TabPanel>
          <TabPanel>
            <ProductPricingAndLogisticsPanel {...props} mode={"view"} />
          </TabPanel>
          <TabPanel>
            <HistoryPanel {...props} />
          </TabPanel>
          <TabPanel>
            <CompliancePanel {...props} />
          </TabPanel>
          <TabPanel>
            <ImpactPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

const minMaxDimensions = (dimensions: Array<number>) => {
  if (dimensions.length > 0) {
    return [Math.min(...dimensions), Math.max(...dimensions)]
  } else {
    return [0, 0]
  }
}

const MainPanel = ({ product }: ProductProps) => {
  const lengthsCm = product.variants.map(
    (variant) => variant.dimension?.lengthCm,
  )
  const widthsCm = product.variants.map((variant) => variant.dimension?.widthCm)
  const heightsCm = product.variants.map(
    (variant) => variant.dimension?.heightCm,
  )
  const weightsKg = product.variants.map(
    (variant) => variant.dimension?.weightKg,
  )

  lengthsCm.push(product.dimension?.lengthCm)
  widthsCm.push(product.dimension?.widthCm)
  heightsCm.push(product.dimension?.heightCm)
  weightsKg.push(product.dimension?.weightKg)

  const filteredLengthsCm: number[] = lengthsCm.filter(
    (x): x is number => x !== null,
  )
  const filteredWidthsCm: number[] = widthsCm.filter(
    (x): x is number => x !== null,
  )
  const filteredHeightsCm: number[] = heightsCm.filter(
    (x): x is number => x !== null,
  )
  const filteredWeightsKg: number[] = weightsKg.filter(
    (x): x is number => x !== null,
  )

  const rangeLengths = minMaxDimensions(filteredLengthsCm)
  const rangeWidths = minMaxDimensions(filteredWidthsCm)
  const rangeHeights = minMaxDimensions(filteredHeightsCm)
  const rangeWeights = minMaxDimensions(filteredWeightsKg)

  const UnitRangeDisplay = (props: {
    minMax: number[]
    unitName: string
    unit: string
  }) => {
    const { minMax, unit, unitName } = props

    if (minMax[0] !== minMax[1]) {
      return (
        <>
          <UnitValue value={minMax[0]} unit={unit} unitName={unitName} />
          <span className="mx-1">-</span>
          <UnitValue value={minMax[1]} unit={unit} unitName={unitName} />
        </>
      )
    } else {
      return <UnitValue value={minMax[1]} unit={unit} unitName={unitName} />
    }
  }

  return (
    <>
      <TabEditLink {...product} hash="main" />

      <BorderBoxContainer>
        <BorderBoxHeading>Product Description</BorderBoxHeading>
        {product.description ? (
          <BorderBox>{product.description}</BorderBox>
        ) : (
          <BorderBoxEmpty>No Description has been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Information</BorderBoxHeading>
        <BorderBox>
          <table className="table table-borderless nice-table">
            <tbody>
              <tr>
                <td className="pb-0">
                  <span className="pr-2 text-uppercase">
                    Production Method:
                  </span>
                  {product.productionMethod || <EmptyValue variant="message" />}
                </td>
                <td className="pb-0">
                  <span className="pr-2 text-uppercase">HTS Code:</span>
                  {product.htsCode || <EmptyValue variant="message" />}
                </td>
              </tr>
              <tr>
                <td className="pb-0">
                  <span className="pr-2 text-uppercase">Fabric Type:</span>
                  {product.fabricType === "na"
                    ? "N/A"
                    : _.capitalize(product.fabricType || undefined) || (
                        <EmptyValue variant="message" />
                      )}
                </td>
                <td className="pb-0">
                  <span className="pr-2 text-uppercase">Max Capacity:</span>
                  {numberWithCommas(product.maxCapacity) || (
                    <EmptyValue variant="message" />
                  )}
                </td>
              </tr>
              <tr>
                <td className="pb-0 border-0">
                  <span className="pr-2 text-uppercase">
                    Price-Affecting Variants:
                  </span>
                  {product.variants.map((value) => value.name).join(", ") || (
                    <EmptyValue variant="message" />
                  )}
                </td>
                <td className="pb-0 border-0">
                  <span className="pr-2 text-uppercase">
                    Non-Price-Affecting Variants:
                  </span>
                  {product.npaVariants
                    .map((value) => value.name)
                    .join(", ") || <EmptyValue variant="message" />}
                </td>
              </tr>
              <tr>
                <td className="pb-0">
                  <span className="pr-2 text-uppercase">Available Until:</span>
                  {product.availableUntil ? (
                    new Date(product.availableUntil).toLocaleDateString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "UTC",
                      },
                    )
                  ) : (
                    <EmptyValue variant="message" />
                  )}
                  {product.availableUntil &&
                    differenceInMonths(
                      Date.parse(product.availableUntil),
                      Date.now(),
                    ) < 1 && (
                      <p className="text-danger mb-1">
                        Product may not be available. Contact maker directly to
                        confirm availability.
                      </p>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-100">
            <div className="d-flex justify-content-between">
              <span className="pr-2 text-uppercase">Dimensions:</span>
              <UnitToggle />
            </div>
            <div>
              <div className="d-flex">
                <div className="col-3 p-0">
                  <UnitLabel label="Length" unitName="length" />
                </div>
                <div className="col-3 p-0">
                  <UnitLabel label="Width" unitName="length" />
                </div>
                <div className="col-3 p-0">
                  <UnitLabel label="Height" unitName="length" />
                </div>
                <div className="col-3 p-0">
                  <UnitLabel label="Weight" unitName="mass" />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 p-0">
                  <UnitRangeDisplay
                    minMax={rangeLengths}
                    unit="cm"
                    unitName="length"
                  />
                </div>
                <div className="col-3 p-0">
                  <UnitRangeDisplay
                    minMax={rangeWidths}
                    unit="cm"
                    unitName="length"
                  />
                </div>
                <div className="col-3 p-0">
                  <UnitRangeDisplay
                    minMax={rangeHeights}
                    unit="cm"
                    unitName="length"
                  />
                </div>
                <div className="col-3 p-0">
                  <UnitRangeDisplay
                    minMax={rangeWeights}
                    unit="kg"
                    unitName="mass"
                  />
                </div>
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <div className="d-xl-grid grid-xl-cols-2 gap-xl-4">
        <BorderBoxContainer>
          <BorderBoxHeading>Product Categories</BorderBoxHeading>
          {product.productCategories.length > 0 ? (
            <BorderBox>
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Subcategories</th>
                  </tr>
                </thead>
                <tbody>
                  {product.productProductCategories.nodes.map(
                    ({ productSubcategories, productCategory, ...ppc }) => (
                      <tr key={ppc.id}>
                        <td>{productCategory.name}</td>
                        <td>
                          {productSubcategories.nodes.length > 0 ? (
                            <div className="d-flex flex-wrap flex-grow-1">
                              {productSubcategories.nodes.map(({ name }) => (
                                <div
                                  className="bg-teal text-light rounded p-2 mr-2 mb-2"
                                  key={name}
                                >
                                  {name}
                                </div>
                              ))}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </BorderBox>
          ) : (
            <BorderBoxEmpty>
              No Product Categories have been added yet.
            </BorderBoxEmpty>
          )}
        </BorderBoxContainer>

        <BorderBoxContainer>
          <BorderBoxHeading>Materials</BorderBoxHeading>

          {product.productMaterials.nodes.length > 0 ? (
            <BorderBox>
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Section</th>
                    <th>Sub-Section</th>
                  </tr>
                </thead>
                <tbody>
                  {product.productMaterials.nodes.map((pm) => (
                    <tr key={pm.id}>
                      <td>{pm.material.name}</td>
                      <td>
                        <div className="d-flex flex-wrap">
                          {pm.materialSubcategories.nodes.map((subcat) => (
                            <BorderBoxItem key={subcat.id}>
                              {subcat.name}
                            </BorderBoxItem>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </BorderBox>
          ) : (
            <BorderBoxEmpty>No Materials have been added yet.</BorderBoxEmpty>
          )}
        </BorderBoxContainer>
      </div>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Notes</BorderBoxHeading>
        {product.internalNotes ? (
          <BorderBox>{product.internalNotes}</BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Internal Notes have been added yet.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <div className="d-grid grid-cols-2 gap-4">
        <BorderBoxContainer>
          <BorderBoxHeading>Images</BorderBoxHeading>
          {product.images.length > 0 ? (
            <BorderBox>
              <div className="d-grid grid-cols-3 gap-4 mb-2">
                {product.images.map((image) => (
                  <ImageWithControls
                    key={image.id}
                    {...image}
                    editUrl={product.editUrl}
                  />
                ))}
              </div>
            </BorderBox>
          ) : (
            <BorderBoxEmpty>No Images have been added yet.</BorderBoxEmpty>
          )}
        </BorderBoxContainer>

        <BorderBoxContainer>
          <BorderBoxHeading>Tech Pack</BorderBoxHeading>
          {product.techPack ? (
            <BorderBox>
              <a href={product.techPack.url} className="text-dark-teal">
                {product.techPack.filename}
              </a>
            </BorderBox>
          ) : (
            <BorderBoxEmpty>No Tech Pack has been added yet.</BorderBoxEmpty>
          )}
        </BorderBoxContainer>
      </div>

      <BorderBoxContainer>
        <BorderBoxHeading>Previous Buyers</BorderBoxHeading>
        {product.previousBuyers.length > 0 ? (
          <BorderBox>
            {product.previousBuyers.map((value) => (
              <BorderBoxItem key={value}>{value}</BorderBoxItem>
            ))}
          </BorderBox>
        ) : (
          <BorderBoxEmpty>There are no Previous Buyers.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Non-Price Affecting Variants</BorderBoxHeading>
        {product.npaVariants.length > 0 ? (
          <BorderBox>
            <div className="table-responsive">
              <table className="table nice-table">
                {product.npaVariants.map((npaVariant, i) => [
                  <thead key={`${i}-thead`}>
                    <tr>
                      <th>
                        {npaVariant.name}
                        <span className="text-muted font-weight-normal ml-2">
                          Client Facing Name
                        </span>
                      </th>
                      <th>
                        {npaVariant.name}
                        <span className="text-muted font-weight-normal ml-2">
                          Maker Facing Name
                        </span>
                      </th>
                    </tr>
                  </thead>,
                  <tbody key={`${i}-tbody`}>
                    {npaVariant.npaVariantOptions
                      .sort((a, b) => (a.position || 0) - (b.position || 0))
                      .map((npaVariantOption, i) => (
                        <tr key={i}>
                          <td>{npaVariantOption.name}</td>
                          <td>{npaVariantOption.makerName}</td>
                        </tr>
                      ))}
                  </tbody>,
                ])}
              </table>
            </div>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            There are no Non-Price Affecting Variants.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Style Number</BorderBoxHeading>
        {product.styleNumber ? (
          <BorderBox>{product.styleNumber}</BorderBox>
        ) : (
          <BorderBoxEmpty>No style number has been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Alias</BorderBoxHeading>
        <BorderBox>
          <div className="w-100">
            <BorderBoxHeading>Name Aliases</BorderBoxHeading>
            {product.nameAliases.length > 0 ? (
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {product.nameAliases.map((nameAlias) => (
                    <tr key={nameAlias.id}>
                      <td>{nameAlias.name}</td>
                      <td>{nameAlias.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-muted mb-4">
                No Name Aliases have been added yet.
              </div>
            )}
            <BorderBoxHeading>Style Number Aliases</BorderBoxHeading>
            {product.skuAliases.length > 0 ? (
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {product.skuAliases.map((skuAlias) => (
                    <tr key={skuAlias.id}>
                      <td>{skuAlias.sku}</td>
                      <td>{skuAlias.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-muted mb-4">
                No Style Number Aliases have been added yet.
              </div>
            )}
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

export const ProductPricingAndLogisticsPanel = (props: {
  product: ProductShowMakerFragment | ProductEditMakerFragment
  mode: "edit" | "view"
}) => {
  const result = useProductProfilePricingAndLogisticsQuery({
    id: props.product.id,
  })
  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { product } = result.data

  return (
    <>
      {props.mode === "view" && (
        <TabEditLink
          // @ts-expect-error
          editUrl={props.product.editUrl}
          hash="pricing-and-logistics"
        />
      )}

      <CogsTableSettings
        mode={props.mode}
        makerId={product.maker.id}
        productGroupId={product.productGroup.id}
        productId={product.id}
        defaultAirShippingPortId={product.maker.defaultAirShippingPort?.id}
        defaultSeaShippingPortId={product.maker.defaultSeaShippingPort?.id}
        otherAirShippingPortId={
          // seems wrong, but matches existing behavior
          product.maker.shippingPorts.nodes.find(
            (x) => x.modeOfTransit === ModeOfTransit.Air,
          )?.id
        }
        otherSeaShippingPortId={
          // seems wrong, but matches existing behavior
          product.maker.shippingPorts.nodes.find(
            (x) => x.modeOfTransit === ModeOfTransit.Sea,
          )?.id
        }
      />

      {/* NOTE(AM): turnaround table currently rendered with cogs table
      <BorderBoxContainer>
        <BorderBoxHeading>Turnaround Time</BorderBoxHeading>
        <BorderBox>
          <table className="table nice-table">
            <thead>
              <tr>
                <th>Product Name</th>
                {product.productGroup.cogsColumns.map((cogsColumn) => (
                  <th key={cogsColumn.id} id={cogsColumn.id}>
                    {cogsColumn.unitsMin}
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="f-small-text" data-testid="turnaround-table-body">
              {product.variants.map((variant) => (
                <tr key={variant.id}>
                  <td>{variant.name}</td>
                  {product.productGroup.cogsColumns.map((cogsColumn) => {
                    const cogsVariantCell = variant.cogsVariantCells.find(
                      (cogsVariantCell) =>
                        cogsVariantCell.cogsColumnId === cogsColumn.id,
                    )
                    return (
                      <td key={cogsColumn.id} id={cogsColumn.id}>
                        {cogsVariantCell ? (
                          cogsVariantCell.turnaroundTimeString
                        ) : (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                    )
                  })}
                  <td>
                    <a
                      href={product.editCogsVariantCellsUrl}
                      className="d-flex mr-2 text-dark-teal"
                    >
                      <PencilSVG style={{ height: 14 }} />
                      <VisuallyHidden>Edit turnaround time</VisuallyHidden>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </BorderBox>
      </BorderBoxContainer>
      */}

      <BorderBoxContainer>
        <BorderBoxHeading>Platform IDs</BorderBoxHeading>
        <BorderBox>
          <div className="w-100 d-flex align-items-end flex-column">
            <UnitToggle />
          </div>

          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Platform ID</th>
                  {product.variants.length > 0 && (
                    <th>{product.variantName}</th>
                  )}
                  {product.npaVariants.map((npaVariant) => (
                    <th key={npaVariant.id}>{npaVariant.name}</th>
                  ))}
                  <th>HTS Code</th>
                  <th>
                    <UnitLabel label="Weight" unitName="mass" />
                  </th>
                  <th>
                    <UnitLabel label="Height" unitName="length" />
                  </th>
                  <th>
                    <UnitLabel label="Width" unitName="length" />
                  </th>
                  <th>
                    <UnitLabel label="Length" unitName="length" />
                  </th>
                  <th>Shipping Price By</th>
                </tr>
              </thead>
              <tbody className="f-small-text">
                {product.skus.map((sku) => (
                  <tr key={sku.id}>
                    <td>{sku.humanId}</td>
                    {product.variants.length > 0 && <td>{sku.variant.name}</td>}
                    {product.npaVariants.map((npaVariant) => {
                      const npaVariantOption = sku.npaVariantOptions.find(
                        (option) => option.npaVariantId === npaVariant.id,
                      )

                      return (
                        <td key={npaVariant.id}>
                          {npaVariantOption?.name || (
                            <EmptyValue variant="dash" />
                          )}
                        </td>
                      )
                    })}
                    <td>
                      {sku.variant.htsCode || <EmptyValue variant="dash" />}
                    </td>
                    <td>
                      <UnitValue
                        value={sku.variant.weightKg || 0}
                        unit="kg"
                        unitName="mass"
                      />
                    </td>
                    <td>
                      <UnitValue
                        value={sku.variant.heightCm || 0}
                        unit="cm"
                        unitName="length"
                      />
                    </td>
                    <td>
                      <UnitValue
                        value={sku.variant.widthCm || 0}
                        unit="cm"
                        unitName="length"
                      />
                    </td>
                    <td>
                      <UnitValue
                        value={sku.variant.lengthCm || 0}
                        unit="cm"
                        unitName="length"
                      />
                    </td>
                    <td>
                      {sku.variant.shippingPriceBy || (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const HistoryPanel = (props: ProductProps) => (
  <>
    <TabEditLink {...props.product} hash="history" />

    <BorderBoxContainer>
      <BorderBoxHeading>Order History</BorderBoxHeading>
      <ProductOrderHistoryTable id={props.product.id} />
    </BorderBoxContainer>
  </>
)

const CompliancePanel = (props: ProductProps) => {
  const result = useInfiniteGraphQLQuery(
    useProductProfileTestingAndComplianceQuery,
    ({ pageParam }) => ({
      id: props.product.id,
      first: 15,
      after: pageParam,
    }),
    {
      getNextPageParam: (page) =>
        page.product.productTests.pageInfo.endCursor ?? undefined,
    },
  )

  if (result.status === "loading") return <LoadingIndicator />
  if (result.status === "error") return <RequestError {...result} />

  invariant(result.data, `expected data`)

  const productTests = result.data.pages.flatMap(
    (page) => page.product.productTests.nodes,
  )

  // TODO(AM): Figure out why result.hasNextPage isn't producing the same result
  const hasNextPage =
    result.data.pages[result.data.pages.length - 1].product.productTests
      .pageInfo.hasNextPage

  return (
    <>
      <TabEditLink {...props.product} hash="compliance" />

      <BorderBoxContainer>
        <BorderBoxHeading>Testing And Compliance</BorderBoxHeading>
        <BorderBox>
          {productTests.length === 0 ? (
            <EmptyValue variant="message" />
          ) : (
            <div className="table-responsive">
              <table className="table nice-table">
                <thead>
                  <tr>
                    <th>Date of Completion</th>
                    <th>Name</th>
                    <th>Result</th>
                    <th>Notes</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody className="f-small-text">
                  {productTests.map((productTest) => (
                    <tr key={productTest.id}>
                      <td>{formatDate(productTest.dateOfCompletion)}</td>
                      <td>
                        {productTest.name || <EmptyValue variant="dash" />}
                      </td>
                      <td>{productTest.result}</td>
                      <td>
                        {productTest.description || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {productTest.document ? (
                          <a
                            className="d-flex align-items-center text-dark-teal"
                            href={productTest.document.url}
                          >
                            <DownloadSVG style={{ height: 15 }} />
                            <VisuallyHidden>Download document</VisuallyHidden>
                          </a>
                        ) : (
                          <EmptyValue variant="message" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {hasNextPage && (
                <div className="pv-3 d-flex flex-grow-1 justify-content-center">
                  <button
                    onClick={() => result.fetchNextPage()}
                    type="button"
                    className="btn btn-link f-action-text"
                    disabled={result.isFetchingNextPage}
                  >
                    {result.isLoading ? (
                      "Loading..."
                    ) : (
                      <>
                        View More <DownChevronSVG className="ml-1" />
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const ImpactPanel = ({ product }: ProductProps) => (
  <>
    <TabEditLink {...product} hash="impact" />

    <BorderBoxContainer>
      <ProductAttributes product={product} />
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Social Impact Metrics</BorderBoxHeading>
      <BorderBox>
        {product.socialImpactMetricsEnabled ? (
          <div className="d-flex w-100 mb-3">
            <div className="text-uppercase" style={{ minWidth: "180px" }}>
              Fair Wage Work:
            </div>
            <div>
              {numberWithCommas(product.fairWagePerHour) || (
                <EmptyValue variant="message" />
              )}{" "}
              Hours
            </div>
          </div>
        ) : (
          <div className="text-muted">
            No metrics are available for this product.
          </div>
        )}
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Environmental Impact Metrics</BorderBoxHeading>
      <BorderBox>
        {product.environmentalImpactMetricsEnabled ? (
          <>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Energy:
              </div>
              <div>
                {numberWithCommas(product.energyConservedKwh) || (
                  <EmptyValue variant="message" />
                )}{" "}
                kWh of Energy Conserved
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Water:
              </div>
              <div>
                {numberWithCommas(product.waterSavedLiters) || (
                  <EmptyValue variant="message" />
                )}{" "}
                L of Water Saved
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Emissions Avoided:
              </div>
              <div>
                {numberWithCommas(product.emissionsAvoidedKg) || (
                  <EmptyValue variant="message" />
                )}{" "}
                kgCO<sub>2</sub>e of CO<sub>2</sub> Avoided
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Pesticides Avoided:
              </div>
              <div>
                {numberWithCommas(product.pesticidesAvoidedG) || (
                  <EmptyValue variant="message" />
                )}{" "}
                g of Pesticides Avoided
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Waste Diverted:
              </div>
              <div>
                {numberWithCommas(product.wasteDivertedG) || (
                  <EmptyValue variant="message" />
                )}{" "}
                g of Waste Diverted
              </div>
            </div>
          </>
        ) : (
          <div className="text-muted">
            No metrics are available for this product.
          </div>
        )}
      </BorderBox>
    </BorderBoxContainer>

    {product.equivalencesEnabled && (
      <BorderBoxContainer>
        <BorderBoxHeading>Impact Equivalences</BorderBoxHeading>
        <BorderBox>
          <div className="d-flex w-100">
            <div className="col-6">
              {product.bulbEnergySavedHours &&
                product.bulbEnergySavedHours > 0 && (
                  <div className="d-flex align-items-center mb-2">
                    <Lightbulb />
                    <p className="pt-2 pl-2 w-75 font-weight-bold">
                      {numberWithCommas(
                        product.bulbEnergySavedHours.toFixed(3),
                      )}{" "}
                      hours of bulb energy saved
                    </p>
                  </div>
                )}
              <div className="d-flex align-items-center">
                {product.drivingEmissionsAvoidedKm &&
                  product.drivingEmissionsAvoidedKm > 0 && (
                    <div className="d-flex align-items-center mb-2">
                      <DrivingEmissons />
                      <p className="pt-2 pl-2 w-75 font-weight-bold">
                        {numberWithCommas(
                          product.drivingEmissionsAvoidedKm.toFixed(3),
                        )}{" "}
                        km of driving emissions avoided
                      </p>
                    </div>
                  )}
              </div>
              <div className="d-flex align-items-center">
                {product.recycledWaterBottles &&
                  product.recycledWaterBottles > 0 && (
                    <div className="d-flex align-items-center mb-2">
                      <Recycled />
                      <p className="pt-2 pl-2 w-75 font-weight-bold">
                        {numberWithCommas(
                          product.recycledWaterBottles.toFixed(3),
                        )}{" "}
                        water bottles recycled
                      </p>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center mb-2">
                {product.drinkingWaterSavedDays &&
                  product.drinkingWaterSavedDays > 0 && (
                    <div className="d-flex align-items-center mb-2">
                      <DrinkingWater />
                      <p className="pt-2 pl-2 w-75 font-weight-bold">
                        {numberWithCommas(
                          product.drinkingWaterSavedDays.toFixed(3),
                        )}{" "}
                        days of drinking water saved
                      </p>
                    </div>
                  )}
              </div>
              <div className="d-flex align-items-center">
                {product.landSavedFromPesticidesM2 &&
                  product.landSavedFromPesticidesM2 > 0 && (
                    <div className="d-flex align-items-center mb-2">
                      <Pesticides />
                      <p className="pt-2 pl-2 w-75 font-weight-bold">
                        {numberWithCommas(
                          product.landSavedFromPesticidesM2.toFixed(3),
                        )}{" "}
                        m² of land saved from pesticides
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    )}

    <BorderBoxContainer>
      <BorderBoxHeading>Carbon Offsetting</BorderBoxHeading>
      <BorderBox>
        {product.carbonOffsetMetricsEnabled ? (
          <>
            <div className="d-flex w-100 mb-3">
              <div className="text-uppercase" style={{ minWidth: "180px" }}>
                Offsetting:
              </div>
              <div>
                {numberWithCommas(product.carbonOffsetKg) || (
                  <EmptyValue variant="dash" />
                )}{" "}
                kgCO
                <sub>2</sub>e
              </div>
            </div>
            <CarbonOffsets {...product} round={false} />
          </>
        ) : (
          <div className="text-muted">
            No metrics are available for this product.
          </div>
        )}
      </BorderBox>
    </BorderBoxContainer>
  </>
)

export default withBasics(ProductProfile)
