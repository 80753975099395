import React from "react"
import { ContactFragment } from "../generated/graphql"
import { withBasics } from "./withBasics"
import { ReactComponent as StarSVG } from "../images/star.svg"

const ContactDetail = ({
  contact,
  isDefault,
}: {
  contact: ContactFragment
  isDefault: boolean
}) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        {contact.fullName || contact.jobTitle ? (
          <div>
            {contact.fullName ? (
              <div className="f-header-3">{contact.fullName}</div>
            ) : null}
            {contact.jobTitle ? (
              <div className="f-small-text" style={{ color: "#9A9C9F" }}>
                {contact.jobTitle}
              </div>
            ) : null}
          </div>
        ) : null}
        {isDefault && (
          <StarSVG
            className="text-dark-teal"
            fill="currentColor"
            style={{ width: 14 }}
          />
        )}
      </div>
      {contact.phone ? (
        <a
          href={`tel:${contact.phone}`}
          className="f-small-text d-block mb-1 text-dark-teal"
        >
          {contact.phone}
        </a>
      ) : null}
      {contact.email ? (
        <a
          href={`mailto:${contact.email}`}
          className="f-small-text d-block mb-1 text-dark-teal"
        >
          {contact.email}
        </a>
      ) : null}
      {contact.timezoneLabel ? (
        <div className="f-small-text mb-1">{contact.timezoneLabel}</div>
      ) : null}
    </>
  )
}

export default withBasics(ContactDetail)
