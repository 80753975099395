import { DirectUpload } from "@rails/activestorage"
import { Tab, TabList, TabPanel, TabPanels } from "@reach/tabs"
import VisuallyHidden from "@reach/visually-hidden"
import clsx from "clsx"
import { getYear } from "date-fns"
import { Field, FormikProvider, useFormik, useFormikContext } from "formik"
import React, { useRef, useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  MakerEditMakerFragment,
  ModeOfTransit,
  UpdateMakerInput,
  UpdateMakerMutation,
  useMakerDocumentsQuery,
  useMakerEditQuery,
  useMakerLogisticsQuery,
  useUpdateMakerMutation,
} from "../generated/graphql"

import { ReactComponent as PencilSVG } from "../images/pencil.svg"
import { ReactComponent as Plus40SVG } from "../images/plus-40.svg"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import { Blobby, blobImageUrl, directUploadUrl } from "../util/active-storage"
import { changedValues } from "../util/changed-values"
import { formatDate } from "../util/dates"
import { makerStatusLabels } from "../util/maker-status-labels"
import { deleteRequest } from "../util/request"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxHeading,
  BorderBoxItem,
} from "./BorderBox"
import CollectionSelect from "./CollectionSelect"
import Contact from "./Contact"
import ContactDetail from "./ContactDetail"
import EditLink from "./EditLink"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import FileDownloadDropdown from "./FileDownloadDropdown"
import { Button, Label } from "./FormElements"
import { ImageWithControls, ImageBackdrop, ImageOnHover } from "./LegacyImage"
import LoadingIndicator from "./LoadingIndicator"
import Tabs from "./Tabs"
import { withBasics } from "./withBasics"
import {
  InputWithFormikErrors,
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
} from "./WithFormikErrors"
import DocumentActions from "./DocumentActions"

type MakerProps = {
  maker: MakerEditMakerFragment
}

const MakerEdit = (props: { slug: string }) => {
  const result = useMakerEditQuery({ slug: props.slug })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const maker = result.data.maker

  return <MakerEditForm maker={maker} />
}

const MakerEditForm = ({ maker }: MakerProps) => {
  const mutation = useUpdateMakerMutation()

  const initialValues = {
    ...maker,
    moqPriceCents: maker.moqPriceCents
      ? (maker.moqPriceCents / 100).toFixed(2)
      : 0.0,
    makerMakerQuestions: maker.makerQuestions.nodes.map((mq) => {
      const makerMakerQuestion = maker.makerMakerQuestions.nodes.find(
        (mmq) => mmq.makerQuestionId === mq.id,
      )

      if (makerMakerQuestion)
        return {
          ...makerMakerQuestion,
          response:
            typeof makerMakerQuestion?.response === "boolean"
              ? makerMakerQuestion.response
                ? "true"
                : "false"
              : "",
        }
      else return { makerQuestionId: mq.id }
    }),
    makerMakerAttributes: maker.makerAttributes.nodes.map((ma) => {
      const makerMakerAttribute = maker.makerMakerAttributes.nodes.find(
        (mma) => mma.makerAttributeId === ma.id,
      )

      if (makerMakerAttribute)
        return {
          ...makerMakerAttribute,
          response:
            typeof makerMakerAttribute?.response === "boolean"
              ? makerMakerAttribute.response
                ? "true"
                : "false"
              : "",
        }
      else return { makerAttributeId: ma.id }
    }),
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any, { setFieldError }) => {
      const params: any = changedValues(maker, values)

      // TODO(AM): since the maker type upcases the status, revert it to lower case- better way to handle this?
      if (params.status) params.status = params.status.toLowerCase()

      if (params.moqPriceCents)
        params.moqPriceCents = (params.moqPriceCents * 100).toString()

      const input: UpdateMakerInput = {
        id: maker.id,
        ...params,
      }

      const {
        updateMaker: result,
      }: UpdateMakerMutation = await mutation.mutateAsync({
        input,
      })

      if (result && result.errors.length) {
        for (let error of result.errors)
          if (error.path) setFieldError(error.path[1], error.message)
      } else {
        window.location.href = maker.showUrl + (window.location.hash || "")
      }
    },
  })

  const tabIds = [
    "#basic-information",
    "#main",
    "#storytelling",
    "#documents",
    "#logistics",
    "#policies",
  ]

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-lg-row">
            <div className="mr-auto mb-2">
              <Header maker={maker} />
            </div>
            <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
              <Status maker={maker} />
            </div>
          </div>

          <div className="card border-light-teal">
            <div className="card-body">
              <div className="d-flex justify-content-end align-items-baseline">
                <a href={maker.showUrl} className="text-danger mr-4">
                  Back
                </a>
                <Button variant="dark" type="submit">
                  Save
                </Button>
              </div>

              <Tabs tabIds={tabIds}>
                <div className="d-flex flex-grow-1 justify-content-center mb-4">
                  <TabList>
                    <StyledTab>Basic Information</StyledTab>
                    <StyledTab>Main</StyledTab>
                    <StyledTab>Storytelling</StyledTab>
                    <StyledTab>Documents</StyledTab>
                    <StyledTab>Logistics</StyledTab>
                    <StyledTab>Policies</StyledTab>
                  </TabList>
                </div>
                <TabPanels>
                  <TabPanel>
                    <BasicInformationPanel maker={maker} />
                  </TabPanel>
                  <TabPanel>
                    <MainPanel maker={maker} />
                  </TabPanel>
                  <TabPanel>
                    <StoryTellingPanelEdit maker={maker} />
                  </TabPanel>
                  <TabPanel>
                    <DocumentsPanel slug={maker.slug} />
                  </TabPanel>
                  <TabPanel>
                    <LogisticsPanel slug={maker.slug} />
                  </TabPanel>
                  <TabPanel>
                    <PoliciesPanel maker={maker} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

const Header = ({ maker }: MakerProps) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext<UpdateMakerInput>()
  const [logoBlob, setLogoBlob] = useState<Blobby | null>(null)

  const handleLogoUpload = () => {
    if (!fileRef.current) throw new Error(`expected file ref`)
    const files = fileRef.current.files
    if (!files) return

    for (const file of Array.from(files)) {
      const upload = new DirectUpload(file, directUploadUrl)

      upload.create((error, blob) => {
        if (error) {
          alert(`Error uploading file: ${error}`)
          return
        }

        formik.setFieldValue("imageSignedId", blob.signed_id)
        formik.setFieldValue("removeImage", undefined)
        setLogoBlob((blob as unknown) as Blobby)
      })
    }
  }

  return (
    <div className="mt-4 mb-4 d-flex flex-row">
      <div className="mr-30 position-relative">
        {logoBlob ? (
          <img
            src={blobImageUrl(logoBlob)}
            alt=""
            style={{
              width: "100px",
              objectFit: "contain",
              aspectRatio: `1 / 1`,
            }}
          />
        ) : (
          <Imgix
            src={
              formik.values.removeImage
                ? maker.fallbackLogoUrl
                : maker.logoUrlWithFallback
            }
            sizes="100px"
            className="shadow"
          />
        )}
        <ImageBackdrop>
          <ImageOnHover>
            <input
              type="file"
              ref={fileRef}
              onChange={handleLogoUpload}
              accept="image/*"
              className="d-none"
              id="upload-logo-input"
            />
            <label
              htmlFor="upload-logo-input"
              className="d-flex text-dark-teal p-2"
              style={{ cursor: "pointer" }}
            >
              <PencilSVG style={{ width: 14 }} />
              <VisuallyHidden>Edit image</VisuallyHidden>
            </label>
          </ImageOnHover>
          <ImageOnHover>
            {!formik.values.removeImage && (
              <button
                type="button"
                onClick={() => formik.setFieldValue("removeImage", true)}
                className="border-0 bg-transparent d-flex p-2"
              >
                <TrashSVG
                  style={{ width: 12, height: 14 }}
                  className="text-danger"
                />
                <VisuallyHidden>Remove image</VisuallyHidden>
              </button>
            )}
          </ImageOnHover>
        </ImageBackdrop>
      </div>

      <div className="d-flex flex-column">
        <div className="mb-2">
          <Field name="name" as={InputWithFormikErrors} />
        </div>

        <div className="d-flex align-items-baseline mb-4">
          <Label htmlFor="ttmMakerSince" className="mr-4">
            Maker Score
          </Label>
          <Field name="score" as={InputWithFormikErrors} className="w-25" />
        </div>
      </div>
    </div>
  )
}

const Status = ({ maker }: MakerProps) => {
  const formik = useFormikContext()

  const deleteMaker = () => {
    if (
      maker.resourceUrl &&
      window.confirm("Are you sure you want to delete this maker?")
    ) {
      deleteRequest(maker.resourceUrl).then(({ success }) => {
        if (success) window.location.href = "../"
      })
    }
  }

  return (
    <>
      <div className="mb-2">
        <div>
          <span
            className="d-flex align-items-baseline justify-content-end text-danger mb-2"
            style={{ cursor: "pointer" }}
            onClick={deleteMaker}
          >
            Delete Maker
            <TrashSVG style={{ height: 14 }} className="ml-2" />
          </span>
        </div>

        <div className="mb-2">Last Updated: {formatDate(maker.updatedAt)}</div>

        <div className="d-flex align-items-baseline">
          <p className="text-nowrap mr-2">Vetting Status:</p>
          <Field name="vettingStatus" as={SelectWithFormikErrors}>
            {maker.vettingStatusOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </Field>
        </div>

        <div className="d-flex align-items-baseline">
          <p className="text-nowrap mr-2">Order Status:</p>
          <Field name="orderStatus" as={SelectWithFormikErrors}>
            {maker.orderStatusOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </Field>
        </div>
      </div>
      <div
        className="btn-group btn-group-sm mb-2"
        role="group"
        aria-label="Maker Status"
      >
        {Object.entries(makerStatusLabels(maker)).map(([status, label]) => (
          <button
            type="button"
            key={status}
            onClick={() => {
              formik.setFieldValue("status", status)
            }}
            className={clsx(
              "btn btn-sm",
              // @ts-expect-error
              formik.values.status === status
                ? "btn-teal text-light"
                : "btn-outline-teal",
            )}
          >
            {label}
          </button>
        ))}
      </div>
    </>
  )
}

const BorderBoxSubheading: React.FC = (props) => (
  <h6 className="text-uppercase">{props.children}</h6>
)

const BasicInformationPanel = ({
  maker,
}: {
  maker: MakerEditMakerFragment
}) => {
  return (
    <>
      <div className="d-flex align-items-baseline mb-4">
        <Label htmlFor="ttmMakerSince" className="text-nowrap mr-4">
          Maker since:
        </Label>
        <Field
          name="ttmMakerSince"
          as={SelectWithFormikErrors}
          className="w-50"
        >
          {Array.from(
            { length: getYear(new Date()) - 2009 },
            (v, i) => 2010 + i,
          ).map((year) => (
            <option value={`${year}-01-01`} key={year}>
              {year}
            </option>
          ))}
        </Field>
      </div>

      <div className="d-flex align-items-baseline mb-4">
        <Label htmlFor="ttmMakerSince" className="mr-4">
          Website
        </Label>
        <Field name="url" as={InputWithFormikErrors} className="w-50" />
      </div>

      <BorderBoxContainer>
        <BorderBoxHeading>Countries</BorderBoxHeading>
        <BorderBox>
          <Field
            name="countryIds"
            value={maker.countries.nodes}
            as={CollectionSelect}
            options={maker.countriesOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Maker Type(s)</BorderBoxHeading>
        <BorderBox>
          <Field
            name="makerTypes"
            as={CollectionSelect}
            options={maker.makerTypeOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Value Alignments</BorderBoxHeading>
        <BorderBox>
          <Field
            name="valueAlignmentIds"
            value={maker.valueAlignments}
            as={CollectionSelect}
            options={maker.valueAlignmentsOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Maker Environments</BorderBoxHeading>
        <BorderBox>
          <Field
            name="environments"
            as={CollectionSelect}
            options={maker.makerEnvironmentsOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Capabilities</BorderBoxHeading>
        <BorderBox>
          <Field
            name="capabilities"
            as={CollectionSelect}
            options={maker.makerCapabilitiesOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Minimum Order Size</BorderBoxHeading>
        <BorderBox>
          <div className="d-flex flex-column w-100">
            <div className="mb-2">
              <BorderBoxSubheading>Total Units: </BorderBoxSubheading>
              <Field
                name="moqUnits"
                as={InputWithFormikErrors}
                className="w-50"
              />
            </div>

            <div className="mb-2">
              <BorderBoxSubheading>Total Price: </BorderBoxSubheading>
              <div className="input-group w-50">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <Field name="moqPriceCents" as={InputWithFormikErrors} />
              </div>
            </div>

            <div>
              <BorderBoxSubheading>Internal Notes</BorderBoxSubheading>
              <Field name="moqNotes" as={TextAreaWithFormikErrors} />
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Production Capacity</BorderBoxHeading>
        <BorderBox>
          <div className="d-flex flex-column w-100">
            <div className="mb-2">
              <Field
                name="productionCapacity"
                as={SelectWithFormikErrors}
                className="mb-2 w-50"
              >
                {maker.productionCapacityOptions.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Field>
            </div>
            <div>
              <BorderBoxSubheading>Internal Notes</BorderBoxSubheading>
              <Field
                name="productionCapacityNotes"
                as={TextAreaWithFormikErrors}
              />
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const MainPanel = ({ maker }: { maker: MakerEditMakerFragment }) => (
  <>
    <BorderBoxContainer>
      <BorderBoxHeading>Maker Description</BorderBoxHeading>
      <BorderBox>
        <Field name="profile" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <div className="d-flex w-100">
        <BorderBoxHeading>Core Product Categories</BorderBoxHeading>
        <a
          className="ml-auto text-dark-teal"
          href={maker.newMakerProductCategoryUrl}
        >
          Add
        </a>
      </div>

      <BorderBox>
        {maker.makerProductCategories.nodes.length === 0 ? (
          <EmptyValue variant="message" />
        ) : (
          <table className="table nice-table">
            <thead>
              <tr>
                <th>
                  <h6>Name</h6>
                </th>
                <th>
                  <h6>Subcategories</h6>
                </th>
                <th>
                  <h6>Actions</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {maker.makerProductCategories.nodes.map((cat) => (
                <tr key={cat.id}>
                  <td>{cat.productCategory.name}</td>
                  <td>
                    <div className="d-flex flex-wrap">
                      {cat.productSubcategories.nodes.map((subcat) => (
                        <BorderBoxItem key={subcat.id}>
                          {subcat.name}
                        </BorderBoxItem>
                      ))}
                    </div>
                  </td>
                  <td>
                    <EditLink {...cat} variant="full" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <div className="d-flex w-100">
        <BorderBoxHeading>Materials</BorderBoxHeading>
        <a className="ml-auto text-dark-teal" href={maker.newMakerMaterialUrl}>
          Add
        </a>
      </div>

      <BorderBox>
        {maker.makerMaterials.nodes.length === 0 ? (
          <EmptyValue variant="message" />
        ) : (
          <table className="table nice-table">
            <thead>
              <tr>
                <th>
                  <h6>Name</h6>
                </th>
                <th>
                  <h6>Subcategories</h6>
                </th>
                <th>
                  <h6>Actions</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {maker.makerMaterials.nodes.map((makerMaterial) => (
                <tr key={makerMaterial.id}>
                  <td>{makerMaterial.material.name}</td>
                  <td>
                    <div className="d-flex flex-wrap">
                      {makerMaterial.materialSubcategories.nodes.map(
                        (subcat) => (
                          <BorderBoxItem key={subcat.id}>
                            {subcat.name}
                          </BorderBoxItem>
                        ),
                      )}
                    </div>
                  </td>
                  <td>
                    <EditLink {...makerMaterial} variant="full" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Internal Notes</BorderBoxHeading>
      <BorderBox>
        <Field name="internalNotes" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>{`Clients external to ${maker.brandName}`}</BorderBoxHeading>
      <BorderBox>
        <Field
          name="customers"
          as={CollectionSelect}
          options={maker.customersOptions}
        />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Licenses</BorderBoxHeading>
      <BorderBox>
        <Field
          name="licenses"
          as={CollectionSelect}
          options={maker.licensesOptions}
        />
      </BorderBox>
    </BorderBoxContainer>
  </>
)

const StyledTab: React.FC = (props) => (
  <Tab className="btn tab-button" {...props} />
)

const StoryTellingPanelEdit = ({
  maker,
}: {
  maker: MakerEditMakerFragment
}) => (
  <>
    <BorderBoxContainer>
      <BorderBoxHeading>Social Impact</BorderBoxHeading>
      <BorderBox>
        <Field name="socialImpactDescription" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Environmental Impact</BorderBoxHeading>
      <BorderBox>
        <Field
          name="environmentalImpactDescription"
          as={TextAreaWithFormikErrors}
        />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Notable Fact</BorderBoxHeading>
      <BorderBox>
        <Field name="funFact" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Hang Tag Language</BorderBoxHeading>
      <BorderBox>
        <Field name="hangTagLanguage" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Demographics</BorderBoxHeading>
      <BorderBox>
        <table className="table nice-table">
          <tbody>
            <tr>
              <td>Number of employees</td>
              <td>
                <Field name="numberOfEmployees" as={InputWithFormikErrors} />
              </td>
            </tr>
            <tr>
              <td>Number of women employees</td>
              <td>
                <Field
                  name="numberOfWomenEmployees"
                  as={InputWithFormikErrors}
                />
              </td>
            </tr>
            {/* TODO(AM): add percentage of female employees */}
          </tbody>
        </table>
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Marketing Photos</BorderBoxHeading>
      <BorderBox>
        <div className="d-grid grid-cols-6 gap-4 mb-2">
          {maker.makerMarketingImages.nodes.map((mmi) => (
            <ImageWithControls
              key={mmi.id}
              {...mmi}
              imgixParams={{ ar: `2:1` }}
            />
          ))}
          <a
            href={maker.newMakerMarketingImageUrl}
            className="border border-dashed rounded border-light-teal px-3 pt-3 pb-2 d-flex flex-wrap flex-grow-1 align-items-center justify-content-center"
          >
            <Plus40SVG />
            <VisuallyHidden>Add Marketing Image</VisuallyHidden>
          </a>
        </div>
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Attributes</BorderBoxHeading>
      <BorderBox>
        <div className="w-100">
          {maker.makerAttributes.nodes.map((makerAttribute, i) => {
            return (
              <div key={i}>
                <div className="row">
                  <div className="col-8">
                    <p>{makerAttribute.text}</p>
                  </div>

                  <div className="col-4 text-right">
                    <label>
                      <Field
                        name={`makerMakerAttributes.${i}.response`}
                        type="radio"
                        value="true"
                        className="mr-2"
                      />
                      Yes
                    </label>

                    <label className="pl-4 pr-2">
                      <Field
                        name={`makerMakerAttributes.${i}.response`}
                        type="radio"
                        value="false"
                        className="mr-2"
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="mx-1 mb-4">
                  <Field
                    name={`makerMakerAttributes.${i}.responseText`}
                    as={TextAreaWithFormikErrors}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </BorderBox>
    </BorderBoxContainer>
  </>
)

const DocumentsPanel = ({ slug }: { slug: string }) => {
  const result = useMakerDocumentsQuery({
    slug,
  })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { maker } = result.data

  return (
    <>
      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Certifications</BorderBoxHeading>
          <a
            className="ml-auto text-dark-teal"
            href={maker.newMakerCertificationUrl}
          >
            Add
          </a>
        </div>
        {maker.makerCertifications.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Certificate</th>
                  <th>Certifier</th>
                  <th>Active</th>
                  <th>Expiration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {maker.makerCertifications.nodes.map((cert) => (
                  <tr key={cert.id}>
                    <td>
                      <Imgix
                        src={cert.certification.icon.imgixUrl}
                        width={16}
                        height={16}
                        className="mr-2"
                      />
                      {cert.certification.name}
                    </td>

                    <td>{cert.certifier}</td>
                    <td>{formatDate(cert.activeFrom, "date")}</td>
                    <td>{formatDate(cert.expiredAt, "date")}</td>
                    <td>
                      <DocumentActions document={cert} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading uppercase={false}>MOUs / NDAs</BorderBoxHeading>
          <a className="ml-auto text-dark-teal" href={maker.newMakerNdaUrl}>
            Add
          </a>
        </div>
        {maker.makerNdas.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {[
                    "Type",
                    "Signer",
                    "Position",
                    "Date of Signature",
                    "Expiration",
                    "Actions",
                  ].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerNdas.nodes.map((nda) => (
                  <tr key={nda.id}>
                    <td>{nda.documentType.toUpperCase()}</td>
                    <td>{nda.signedByName}</td>
                    <td>{nda.signedByPosition}</td>
                    <td>{formatDate(nda.dateOfSignature)}</td>
                    <td>
                      {formatDate(nda.expirationDate) ?? (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      <DocumentActions document={nda} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Vetting Survey</BorderBoxHeading>
          <a
            className="ml-auto text-dark-teal"
            href={maker.newMakerVettingSurveyUrl}
          >
            Add
          </a>
        </div>
        {maker.makerVettingSurveys.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {[
                    "Completed By",
                    "Position",
                    "Completed On",
                    "Approved By",
                    "Approved At",
                    "Actions",
                  ].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerVettingSurveys.nodes.map((survey) => (
                  <tr key={survey.id}>
                    <td>
                      {survey.completedBy ? (
                        <Contact contact={survey.completedBy} />
                      ) : (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      {survey.completedBy?.jobTitle || (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>{formatDate(survey.completedAt, "date")}</td>
                    <td>
                      {survey.approvedBy?.fullName ?? (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      {formatDate(survey.approvedAt) ?? (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="mr-2 d-flex align-items-center">
                          <EditLink {...survey} className="p-1" />
                        </div>
                        <FileDownloadDropdown attachments={survey.surveys} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Audits</BorderBoxHeading>
          <a className="ml-auto text-dark-teal" href={maker.newMakerAuditUrl}>
            Add
          </a>
        </div>
        {maker.makerAudits.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {[
                    "Name",
                    "Completion",
                    "Expiration",
                    "Performed By",
                    "Results",
                    "Actions",
                  ].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerAudits.nodes.map((audit) => (
                  <tr key={audit.id}>
                    <td>{audit.name}</td>
                    <td>{formatDate(audit.dateOfCompletion, "date")}</td>
                    <td>{formatDate(audit.expirationDate, "date")}</td>
                    <td>
                      {audit.performedBy || <EmptyValue variant="dash" />}
                    </td>
                    <td>{audit.results || <EmptyValue variant="dash" />}</td>
                    <td>
                      <div className="d-flex">
                        <div className="mr-2 d-flex align-items-center">
                          <EditLink {...audit} />
                        </div>
                        <FileDownloadDropdown attachments={audit.documents} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Other Documents</BorderBoxHeading>
          <a
            className="ml-auto text-dark-teal"
            href={maker.newMakerDocumentUrl}
          >
            Add
          </a>
        </div>
        {maker.makerDocuments.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {["Date", "Type", "File Name", "Actions"].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerDocuments.nodes.map((doc) => (
                  <tr key={doc.id}>
                    <td>{formatDate(doc.createdAt)}</td>
                    <td>{doc.documentType}</td>
                    <td>{doc.fileName}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <DocumentActions document={doc} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>
    </>
  )
}

const LogisticsPanel = ({ slug }: { slug: string }) => {
  const result = useMakerLogisticsQuery({
    slug,
  })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { maker } = result.data

  return (
    <>
      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Shipping Ports</BorderBoxHeading>
          <a
            className="ml-auto text-dark-teal"
            href={maker.newMakerShippingPortUrl}
          >
            Add
          </a>
        </div>

        {maker.makerShippingPorts.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {[
                    "Port",
                    "Country",
                    "Mode of Transit",
                    "Default",
                    "Actions",
                  ].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerShippingPorts.nodes.map(
                  ({ shippingPort, ...makerShippingPort }) => (
                    <tr key={makerShippingPort.id}>
                      <td>{shippingPort.port}</td>
                      <td>{shippingPort.country.name}</td>
                      <td>
                        <ModeOfTransitLabel
                          value={shippingPort.modeOfTransit}
                        />
                      </td>
                      <td>{makerShippingPort.default ? "Default" : null}</td>
                      <td>
                        <EditLink {...makerShippingPort} />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Export Experience</BorderBoxHeading>
        <BorderBox>
          <Field name="exportExperience" as={SelectWithFormikErrors}>
            {maker.exportExperienceOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </Field>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Exporting To</BorderBoxHeading>
        <BorderBox>
          <Field
            name="exportingCountryIds"
            value={maker.exportingCountries.nodes}
            as={CollectionSelect}
            options={maker.countriesOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Operation Notes</BorderBoxHeading>
        <BorderBox>
          <Field name="internalOperationNotes" as={TextAreaWithFormikErrors} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Contacts</BorderBoxHeading>
          <a className="ml-auto text-dark-teal" href={maker.newMakerContactUrl}>
            Add
          </a>
        </div>
        <div className="d-grid grid-cols-2 gap-4">
          {maker.makerContacts.nodes.map(({ contact, ...makerContact }) => (
            <BorderBox key={makerContact.id}>
              <div className="position-relative flex-grow-1">
                <div
                  className="position-absolute "
                  style={{ top: 0, right: 0 }}
                >
                  <EditLink {...makerContact} />
                </div>
                <ContactDetail isDefault={false} contact={contact} />
              </div>
            </BorderBox>
          ))}
        </div>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Address</BorderBoxHeading>
        <BorderBox>
          <Field
            name="address"
            as={TextAreaWithFormikErrors}
            className="mb-2"
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Incoterms</BorderBoxHeading>
        <BorderBox>
          <Field
            name="incotermIds"
            value={maker.incoterms.nodes}
            as={CollectionSelect}
            options={maker.incotermsOptions}
          />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Preferred Parcel Carriers</BorderBoxHeading>
        <BorderBox>
          <Field
            name="parcelCarrierIds"
            value={maker.parcelCarriers.nodes}
            as={CollectionSelect}
            options={maker.parcelCarriersOptions}
          />
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const ModeOfTransitLabel = ({ value }: { value: ModeOfTransit }) => {
  const labelMap: Record<ModeOfTransit, string> = {
    AIR: "Air",
    LAND: "Land",
    SEA: "Sea",
  }
  const label = labelMap[value]

  return <>{label}</>
}

const PoliciesPanel = ({ maker }: { maker: MakerEditMakerFragment }) => (
  <div className="pt-6">
    {maker.makerQuestions.nodes.map((makerQuestion, i) => {
      return (
        <div key={i}>
          <div className="row">
            <div className="col-8">
              <p>{makerQuestion.question}</p>
            </div>

            <div className="col-4">
              <label>
                <Field
                  name={`makerMakerQuestions.${i}.response`}
                  type="radio"
                  value="true"
                  className="mr-2"
                />
                Yes
              </label>

              <label className="pl-4">
                <Field
                  name={`makerMakerQuestions.${i}.response`}
                  type="radio"
                  value="false"
                  className="mr-2"
                />
                No
              </label>
            </div>
          </div>
          <div className="mx-1 mb-4">
            <Field
              name={`makerMakerQuestions.${i}.responseText`}
              as={TextAreaWithFormikErrors}
            />
          </div>
        </div>
      )
    })}
  </div>
)

export default withBasics(MakerEdit)
