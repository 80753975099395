import React from "react"
import clsx from "clsx"
import VisuallyHidden from "@reach/visually-hidden"
import { ReactComponent as PencilSVG } from "../images/pencil.svg"

const EditLink = ({
  editUrl,
  className,
  anchor,
  variant = "full",
  width = 10,
}: {
  editUrl: string
  className?: string
  anchor?: string
  variant?: "icon" | "full"
  width?: number
}) => {
  const url = new URL(editUrl)
  if (anchor != null) url.hash = anchor
  return (
    <a
      href={url.toString()}
      className={clsx(
        "d-flex align-self-center align-items-center text-dark-teal",
        className,
      )}
    >
      <PencilSVG style={{ width }} />
      {variant === "full" ? (
        <div className="small pl-1">Edit</div>
      ) : variant === "icon" ? (
        <VisuallyHidden>Edit</VisuallyHidden>
      ) : null}
    </a>
  )
}

export const TabEditLink = ({
  editUrl,
  hash,
}: {
  editUrl: string
  hash?: string
}) => (
  <div
    className="d-flex flex-grow-1 position-absolute"
    style={{ top: 0, right: 0 }}
  >
    <div className="ml-auto">
      <EditLink editUrl={`${editUrl}${hash ? `#${hash}` : ""}`} />
    </div>
  </div>
)

export default EditLink
