import React, { useState } from "react"
import { useMakerOrderSearchQuery } from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { useDebounce } from "use-debounce"
import MakerOrderListResults from "./MakerOrderListResults"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { FiltersDisclosureButton, SearchInput } from "./SearchElements"
import { Disclosure, DisclosurePanel } from "@reach/disclosure"
import useParams from "../hooks/useParams"
import MakerOrderSearchFilters, {
  DEFAULT_MAKER_ORDER_FILTERS,
  MakerOrderFilters,
} from "./MakerOrderSearchFilters"

const MakerOrdersListScreen = (props: { showNewOrderButton: boolean }) => {
  const [params, setParams] = useParams()
  // @ts-expect-error todo
  const [query, setQuery] = useState(params.q || "")
  const [searchQuery] = useDebounce(query, 300)
  const [filters, setFilters] = useState<MakerOrderFilters>(
    DEFAULT_MAKER_ORDER_FILTERS,
  )

  const result = useInfiniteGraphQLQuery(
    useMakerOrderSearchQuery,
    ({ pageParam }) => ({
      query: searchQuery,
      after: pageParam,
      ...filters,
    }),
    {
      getNextPageParam: (page) =>
        page.makerOrders.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <>
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto">
          <h1 className="my-0 mr-3 f-title mb-5">Maker Orders</h1>
          {result.isFetching && <LoadingIndicator />}
        </div>
      </div>

      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            onChange={(e) => {
              setQuery(e.currentTarget.value)
              // @ts-expect-error todo
              setParams({ q: e.currentTarget.value }, { replace: true })
            }}
            value={query}
            placeholder="Search by Order Number or Description"
          />
          <FiltersDisclosureButton />
        </div>

        <DisclosurePanel>
          <div className="border rounded border-light-teal position-relative">
            <div className="p-3">
              <MakerOrderSearchFilters
                setFilters={setFilters}
                filters={filters}
              />
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <MakerOrderListResults result={result} />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3 mt-3"
          disabled={!!result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </>
  )
}

export default withBasics(MakerOrdersListScreen)
