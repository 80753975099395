import React from "react"
import VisuallyHidden from "@reach/visually-hidden"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { ReactComponent as EyeSVG } from "../images/eye.svg"
import { withBasics } from "./withBasics"
import EditLink from "./EditLink"

type DocumentActionsDocument = {
  editUrl: string
  documentUrlInline?: string | null | undefined
  documentUrlAttachment?: string | null | undefined
}

const DocumentActions = ({
  document,
}: {
  document: DocumentActionsDocument
}) => {
  return (
    <div className="d-flex">
      <EditLink {...document} className="mr-2" />

      {document.documentUrlInline ? (
        <a
          href={document.documentUrlInline}
          className="d-flex mr-2 text-dark-teal"
        >
          <EyeSVG style={{ width: 17 }} />
          <VisuallyHidden>View document</VisuallyHidden>
        </a>
      ) : null}

      {document.documentUrlAttachment ? (
        <a
          href={document.documentUrlAttachment}
          className="d-flex text-dark-teal"
        >
          <DownloadSVG />
          <VisuallyHidden>Download document</VisuallyHidden>
        </a>
      ) : null}
    </div>
  )
}

export default withBasics(DocumentActions)
