import React, { RefObject, useCallback, useEffect, useState } from "react"
import { withBasics } from "./withBasics"
import {
  TimeAndActionCalendarTask,
  TimeAndActionCalendarTaskInput,
  TimeAndActionCalendarTaskOwnerInput,
  useUpdateTimeAndActionMutation,
} from "../generated/graphql"
import {
  Field,
  FieldArray,
  FormikErrors,
  FieldArrayRenderProps,
  Formik,
} from "formik"
import {
  InputWithFormikErrors,
  SelectWithFormikErrors,
  DateInputWithFormikErrors,
  CheckboxWithFormikErrors,
} from "./WithFormikErrors"
import { ReactComponent as TrashSVG } from "../images/trash.svg"
import VisuallyHidden from "@reach/visually-hidden"
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableStateSnapshot,
  DraggableProvided,
} from "react-beautiful-dnd"
import { format } from "date-fns"
import { getDaysBetweenDates } from "../util/dates"
import SearchSelect from "./SearchSelect"

type TaskEditorProps = {
  tasks: any
  template: Boolean
  submit: (tasks: TimeAndActionCalendarTaskInput[]) => void
  ownerOptions?: OwnerOption[]
}

type OwnerOption = {
  id: string
  fullName: string
}

const StageOptions = {
  pre_development: "Pre-Development",
  development: "Development",
  production: "Production",
  post_production: "Post-Production",
}

const TimeAndActionCalendarTasksEdit = ({
  tasks,
  template,
  submit,
  ownerOptions,
}: TaskEditorProps) => {
  const [initialValues, setInitialValues] = useState<FormInput>({
    calendarTasks: [],
  })

  const mutation = useUpdateTimeAndActionMutation()
  type FormInput = {
    calendarTasks: TimeAndActionCalendarTaskInput[]
  }

  const newTaskAttributes = useCallback(
    (position: number): TimeAndActionCalendarTaskInput => {
      return {
        name: "",
        stage: "pre_development",
        remindersOn: false,
        displayToMaker: true,
        status: "not_started",
        startDate: !template ? format(new Date(), "yyyy-MM-dd") : null,
        position: position,
        owners: [],
      }
    },
    [template],
  )

  useEffect(() => {
    if (tasks.length > 0) {
      setInitialValues({
        calendarTasks: tasks
          .sort((a: any, b: any) => a.position - b.position)
          .map((task: TimeAndActionCalendarTask, i: number) => {
            return {
              ...task,
              position: i + 1,
              owners: task.owners.nodes,
            }
          }),
      })
    } else {
      setInitialValues({
        calendarTasks: [
          newTaskAttributes(1),
          newTaskAttributes(2),
          newTaskAttributes(3),
        ],
      })
    }
  }, [tasks, newTaskAttributes])

  const addError = (
    errors: any,
    index: number,
    key: string,
    errorString: string,
  ) => {
    errors.calendarTasks = errors.calendarTasks || []
    errors.calendarTasks[index] = errors.calendarTasks[index] || {}
    errors.calendarTasks[index][key] = errorString
  }

  const validateRow = (values: FormInput) => {
    let errors: any = {}

    values.calendarTasks.forEach((task, i) => {
      if (task.remove) {
        return
      }

      if (
        task.startDate &&
        task.dueDate &&
        new Date(task.startDate) > new Date(task.dueDate)
      ) {
        addError(errors, i, "startDate", "Start Date should be before Due Date")
        addError(errors, i, "dueDate", "Start Date should be before Due Date")
      }

      if (!task.name) {
        addError(errors, i, "name", "Name is required.")
      }
    })

    return errors
  }

  const handleSubmit: (
    values: FormInput,
    helpers: {
      setSubmitting: (isSubmitting: boolean) => void
      setFieldError: (
        field: keyof FormikErrors<FormInput>,
        message: string | undefined,
      ) => void
    },
  ) => void = async (values, { setSubmitting, setFieldError }) => {
    const errors = validateRow(values)

    if (Object.keys(errors).length === 0) {
      submit(values.calendarTasks)
    } else {
      setSubmitting(false)
    }
  }

  if (initialValues.calendarTasks.length === 0) {
    return <div />
  }

  const newTaskPosition = (currentTasks: TimeAndActionCalendarTaskInput[]) => {
    if (currentTasks.length > 0) {
      return currentTasks[currentTasks.length - 1].position + 1
    } else {
      return 1
    }
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateRow}
    >
      {(props: any) => {
        return (
          <form onSubmit={props.handleSubmit} className="w-100">
            <FieldArray
              name="calendarTasks"
              render={(arrayHelpers) => (
                <>
                  <div
                    className="table-responsive"
                    style={{ overflowX: "scroll", overflowY: "visible" }}
                  >
                    <TaskTable
                      template={template}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={props.setFieldValue}
                      calendarTasks={props.values.calendarTasks}
                      ownerOptions={
                        ownerOptions
                          ? ownerOptions.concat([
                              { id: "maker", fullName: "Maker" },
                            ])
                          : []
                      }
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary mr-3"
                        style={{ minWidth: "150px" }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          window.location.replace(window.location.pathname)
                        }
                        className=" btn btn-sm btn-secondary"
                        style={{ minWidth: "150px" }}
                      >
                        Cancel
                      </button>
                    </div>

                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push(
                          newTaskAttributes(
                            newTaskPosition(props.values.calendarTasks),
                          ),
                        )
                      }
                      className="btn btn-sm text-dark-teal ml-auto"
                      disabled={mutation.isLoading || mutation.isSuccess}
                    >
                      Add Task
                    </button>
                  </div>
                </>
              )}
            />
          </form>
        )
      }}
    </Formik>
  )
}

type TaskTableProps = {
  template: Boolean
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  calendarTasks: TimeAndActionCalendarTaskInput[]
  arrayHelpers: FieldArrayRenderProps
  ownerOptions: OwnerOption[]
}

const TaskTable = ({
  template,
  arrayHelpers,
  setFieldValue,
  calendarTasks,
  ownerOptions,
}: TaskTableProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const onBeforeDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = ({ source, destination }: DropResult) => {
    setIsDragging(false)

    if (!destination) {
      return
    }

    const { index: sourceIndex } = source
    const { index: destinationIndex } = destination

    if (sourceIndex === destinationIndex) {
      return
    }

    const updatedTasks = Array.from(calendarTasks)

    const [removedTask] = updatedTasks.splice(sourceIndex, 1)
    updatedTasks.splice(destinationIndex, 0, removedTask)

    // Update the positions of affected tasks
    const startIndex = Math.min(sourceIndex, destinationIndex)
    const endIndex = Math.max(sourceIndex, destinationIndex)

    for (let i = startIndex; i <= endIndex; i++) {
      updatedTasks[i].position = i + 1
    }

    setFieldValue("calendarTasks", updatedTasks)
  }

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onBeforeDragStart={onBeforeDragStart}
    >
      <table className="table nice-table">
        <thead>
          {!template && (
            <tr style={{ textAlign: "center" }}>
              <th colSpan={3}></th>
              <th colSpan={2}>Projected</th>
              <th colSpan={2}>Actual</th>
              <th colSpan={4}></th>
            </tr>
          )}

          <tr>
            <th>Stage</th>
            <th>Task Name</th>
            <th>Duration (#Days)</th>
            {!template && (
              <>
                <th>Start Date</th>
                <th>Due Date</th>
                <th>Start Date</th>
                <th>Date Completed</th>
              </>
            )}
            {!template && <th>Notify Party</th>}
            <th>Reminders</th>
            <th>Display To Maker</th>
            <th>Remove</th>
          </tr>
        </thead>
        <Droppable droppableId="calendarTasks">
          {(provided: any) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {calendarTasks.map((task, index) => {
                if (task.remove) {
                  return null
                }
                return (
                  <TaskTableRow
                    key={index}
                    task={task}
                    i={index}
                    calendarTasks={calendarTasks}
                    setFieldValue={setFieldValue}
                    arrayHelpers={arrayHelpers}
                    isDragging={isDragging}
                    ownerOptions={ownerOptions}
                    template={template}
                  />
                )
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  )
}

type TaskTableRowProps = {
  template: Boolean
  isDragging: Boolean
  task: TimeAndActionCalendarTaskInput
  i: number
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  calendarTasks: TimeAndActionCalendarTaskInput[]
  arrayHelpers: FieldArrayRenderProps
  ownerOptions: OwnerOption[]
}

const TaskTableRow = ({
  task,
  i,
  setFieldValue,
  calendarTasks,
  arrayHelpers,
  isDragging,
  ownerOptions,
  template,
}: TaskTableRowProps) => {
  const handleStartDateChange = (dateString: string) => {
    setFieldValue(`calendarTasks.${i}.startDate`, dateString)

    if (task.dueDate) {
      const dueDate = new Date(task.dueDate)
      const days = getDaysBetweenDates(new Date(dateString), dueDate) + 1
      setFieldValue(`calendarTasks.${i}.durationDays`, days)
    }
  }

  const handleDueDateChange = (dateString: string) => {
    const newDate = new Date(dateString)
    const startDate = new Date(task.startDate || new Date())
    const days = getDaysBetweenDates(startDate, newDate) + 1
    setFieldValue(`calendarTasks.${i}.dueDate`, dateString)
    setFieldValue(`calendarTasks.${i}.durationDays`, days)
  }

  const handleDurationChange = (event: any) => {
    const days = parseInt(event.target.value)

    if (!template) {
      const startDate = new Date(task.startDate || new Date())
      const endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + days)
      setFieldValue(`calendarTasks.${i}.dueDate`, format(endDate, "yyyy-MM-dd"))
    }

    setFieldValue(`calendarTasks.${i}.durationDays`, days)
  }

  const setOwners = (taskPos: number, selectedOwners: OwnerOption[]) => {
    let currentOwners: any = calendarTasks.find(
      (task) => task.position === taskPos,
    )?.owners

    currentOwners = currentOwners
      ?.map((cOwner: any) => {
        let selectedOwner: any

        if (cOwner.maker) {
          selectedOwner = selectedOwners.find((sOwner) => sOwner.id === "maker")
        } else {
          selectedOwner = selectedOwners.find(
            (sOwner) => cOwner.user?.id === sOwner.id,
          )
        }

        if (selectedOwner) {
          cOwner.remove = false
        } else {
          if (cOwner.id) {
            cOwner.remove = true
          } else {
            return null
          }
        }

        return cOwner
      })
      .filter((n: any) => n)

    selectedOwners?.forEach((sOwner: OwnerOption) => {
      let currentOwner: any
      if (sOwner.id === "maker") {
        currentOwner = currentOwners.find((cOwner: any) => cOwner.maker)
      } else {
        currentOwner = currentOwners?.find(
          (cOwner: any) => cOwner.user?.id === sOwner.id,
        )
      }

      if (!currentOwner) {
        if (sOwner.id === "maker") {
          currentOwners?.push({
            maker: true,
          })
        } else {
          currentOwners?.push({
            maker: false,
            user: {
              id: sOwner.id,
              fullName: sOwner.fullName,
            },
          })
        }
      }
    })
    setFieldValue(`calendarTasks[${taskPos - 1}].owners`, currentOwners)
  }

  return (
    <Draggable draggableId={i.toString()} index={i}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <LockedCell isDragOccurring={isDragging}>
              <div style={{ width: "170px" }}>
                <Field
                  name={`calendarTasks.${i}.stage`}
                  as={SelectWithFormikErrors}
                >
                  {Object.entries(StageOptions).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </div>
            </LockedCell>

            <LockedCell isDragOccurring={isDragging}>
              <div style={{ width: "170px" }}>
                <Field
                  name={`calendarTasks.${i}.name`}
                  as={InputWithFormikErrors}
                />
              </div>
            </LockedCell>

            <LockedCell isDragOccurring={isDragging}>
              <div style={{ width: "70px" }}>
                <Field
                  type="number"
                  min={1}
                  name={`calendarTasks.${i}.durationDays`}
                  as={InputWithFormikErrors}
                  onChange={handleDurationChange}
                />
              </div>
            </LockedCell>

            {!template && (
              <>
                <LockedCell isDragOccurring={isDragging}>
                  <div style={{ width: "110px" }}>
                    <Field
                      name={`calendarTasks.${i}.startDate`}
                      as={DateInputWithFormikErrors}
                      onChange={handleStartDateChange}
                    />
                  </div>
                </LockedCell>

                <LockedCell isDragOccurring={isDragging}>
                  <div style={{ width: "110px" }}>
                    <Field
                      name={`calendarTasks.${i}.dueDate`}
                      as={DateInputWithFormikErrors}
                      onChange={handleDueDateChange}
                    />
                  </div>
                </LockedCell>

                <LockedCell isDragOccurring={isDragging}>
                  <div style={{ width: "110px" }}>
                    <Field
                      name={`calendarTasks.${i}.actualStartDate`}
                      as={DateInputWithFormikErrors}
                      maxDate={new Date()}
                    />
                  </div>
                </LockedCell>

                <LockedCell isDragOccurring={isDragging}>
                  <div style={{ width: "110px" }}>
                    <Field
                      name={`calendarTasks.${i}.dateCompleted`}
                      as={DateInputWithFormikErrors}
                      maxDate={new Date()}
                    />
                  </div>
                </LockedCell>
              </>
            )}

            {!template && (
              <LockedCell isDragOccurring={isDragging}>
                <div
                  style={{
                    position: "relative",
                    width: "375px",
                    marginTop: "-32px",
                  }}
                >
                  <SearchSelect
                    options={ownerOptions.map((value) => ({
                      value: value.id,
                      label: value.fullName,
                    }))}
                    values={task.owners.map(
                      (owner: TimeAndActionCalendarTaskOwnerInput) => {
                        if (owner.remove) {
                          return null
                        } else {
                          return {
                            value: owner.maker ? "maker" : owner.user?.id,
                            label: owner.maker
                              ? "Maker"
                              : ownerOptions.find(
                                  (o) => o.id === owner.user?.id,
                                )?.fullName,
                          }
                        }
                      },
                    )}
                    onChange={(options) => {
                      const owners: OwnerOption[] = options.map((sOpt) => {
                        return {
                          id: sOpt.value,
                          fullName: sOpt.label,
                        }
                      })

                      setOwners(task.position, owners)
                    }}
                    label=""
                  />
                </div>
              </LockedCell>
            )}

            <LockedCell isDragOccurring={isDragging}>
              <Field
                name={`calendarTasks.${i}.remindersOn`}
                as={CheckboxWithFormikErrors}
                id={`remindersOn_${i}`}
              />
            </LockedCell>
            <LockedCell isDragOccurring={isDragging}>
              <Field
                name={`calendarTasks.${i}.displayToMaker`}
                as={CheckboxWithFormikErrors}
                id={`displayToMaker_${i}`}
              />
            </LockedCell>

            <LockedCell isDragOccurring={isDragging}>
              <button
                type="button"
                onClick={() => {
                  if (task.id) {
                    setFieldValue(`calendarTasks.${i}.remove`, true)
                  } else {
                    arrayHelpers.remove(i)
                  }
                }}
                className="border-0 bg-transparent d-flex p-2 ml-auto"
              >
                <TrashSVG
                  style={{ width: 12, height: 14 }}
                  className="text-danger"
                />
                <VisuallyHidden>Remove Task</VisuallyHidden>
              </button>
            </LockedCell>
          </tr>
        )
      }}
    </Draggable>
  )
}

interface LockedCellProps {
  isDragOccurring: Boolean
}

interface Snapshot {
  width: number
  height: number
}

// This is copy-pasted code from react-beautiful-dd "dimension locking" fix
// for the dragged row to maintain its width/styles.
// https://github.com/atlassian/react-beautiful-dnd/issues/1829
class LockedCell extends React.Component<LockedCellProps> {
  ref: RefObject<HTMLTableCellElement>

  constructor(props: LockedCellProps) {
    super(props)
    this.ref = React.createRef()
  }

  getSnapshotBeforeUpdate(prevProps: LockedCellProps): Snapshot | null {
    if (!this.ref.current) {
      return null
    }

    const isDragStarting =
      this.props.isDragOccurring && !prevProps.isDragOccurring

    if (!isDragStarting) {
      return null
    }

    const { width, height } = this.ref.current.getBoundingClientRect()

    const snapshot: Snapshot = {
      width,
      height,
    }

    return snapshot
  }

  componentDidUpdate(
    prevProps: LockedCellProps,
    prevState: {},
    snapshot: Snapshot | null,
  ) {
    const ref = this.ref.current
    if (!ref) {
      return
    }

    if (snapshot) {
      if (ref.style.width === `${snapshot.width}px`) {
        return
      }
      ref.style.width = `${snapshot.width}px`
      ref.style.height = `${snapshot.height}px`
      return
    }

    if (this.props.isDragOccurring) {
      return
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty("height")
    ref.style.removeProperty("width")
  }

  setRef = (ref: HTMLTableCellElement) => {
    // @ts-ignore
    this.ref.current = ref
  }

  render() {
    return <td ref={this.setRef}>{this.props.children}</td>
  }
}

export default withBasics(TimeAndActionCalendarTasksEdit)
