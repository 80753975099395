import clsx from "clsx"
import React from "react"
import invariant from "tiny-invariant"
import {
  ProductGroupStatus,
  useUpdateProductGroupMutation,
  UpdateProductGroupMutation,
  UpdateProductGroupInput,
  ProductGroupEditMakerFragment,
  useProductGroupEditQuery,
} from "../generated/graphql"
import { formatDate } from "../util/dates"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import CountriesTable from "./CountriesTable"
import { Field, FormikProvider, useFormik, useFormikContext } from "formik"
import { changedValues } from "../util/changed-values"
import { Button } from "./FormElements"
import {
  InputWithFormikErrors,
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
} from "./WithFormikErrors"
import { ProductImages, ProductTechPack } from "./ProductAttachmentsEdit"
import TableRowActions from "./TableRowActions"

type ProductGroupProps = {
  productGroup: ProductGroupEditMakerFragment
}

const productGroupStatusLabels: Readonly<Record<ProductGroupStatus, string>> = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
}

const ProductGroupEdit = (props: { id: string }) => {
  const result = useProductGroupEditQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const productGroup = result.data.productGroup

  return <ProductGroupEditForm productGroup={productGroup} />
}

const ProductGroupEditForm = ({ productGroup }: ProductGroupProps) => {
  const mutation = useUpdateProductGroupMutation()

  const formik = useFormik({
    initialValues: productGroup,
    enableReinitialize: true,
    onSubmit: async (values: any, { setFieldError }) => {
      const params: any = changedValues(productGroup, values)

      const input: UpdateProductGroupInput = {
        id: productGroup.id,
        ...params,
      }

      const {
        updateProductGroup: result,
      }: UpdateProductGroupMutation = await mutation.mutateAsync({
        input,
      })

      if (result && result.errors.length) {
        for (let error of result.errors)
          if (error.path) setFieldError(error.path[1], error.message)
      } else {
        window.location.href = productGroup.showUrl
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-lg-row">
            <div className="mr-auto mb-2">
              <Header productGroup={productGroup} />
            </div>
            <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
              <Status productGroup={productGroup} />
            </div>
          </div>
          <div className="pb-4">
            <SubHeader productGroup={productGroup} />
          </div>

          <div className="card border-light-teal">
            <div className="card-body">
              <div className="d-flex justify-content-end align-items-baseline">
                <a href={productGroup.showUrl} className="text-danger mr-4">
                  Back
                </a>
                <Button variant="dark" type="submit">
                  Save
                </Button>
              </div>

              <MainPanel productGroup={productGroup} />
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

const Status = ({ productGroup }: ProductGroupProps) => {
  const formik = useFormikContext()

  return (
    <>
      <div className="mb-2">
        <div>Last Updated: {formatDate(productGroup.updatedAt)}</div>
      </div>
      <div
        className="btn-group btn-group-sm"
        role="group"
        aria-label="Product Status"
      >
        {Object.values(ProductGroupStatus).map((status) => (
          <button
            type="button"
            key={status}
            onClick={() => {
              formik.setFieldValue("status", status)
            }}
            className={clsx(
              "btn btn-sm",
              // @ts-expect-error
              formik.values.status === status
                ? "btn-teal text-white"
                : "btn-outline-teal",
            )}
          >
            {productGroupStatusLabels[status]}
          </button>
        ))}
      </div>
    </>
  )
}

const Header = ({ productGroup }: ProductGroupProps) => (
  <div className="mt-4 d-flex flex-row">
    <div className="d-flex flex-column">
      <Field name="name" as={InputWithFormikErrors} />
    </div>
  </div>
)

const SubHeader = ({ productGroup }: ProductGroupProps) => (
  <>
    <table>
      <tbody>
        <tr>
          <td colSpan={2} className="font-weight-bold pr-4">
            ID {productGroup.humanId}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4">Product Categories</td>
          {productGroup.productCategories.length > 0 ? (
            productGroup.productCategories.map((productCategory, i) => (
              <td key={i} className="pr-4">
                {productCategory.name}
              </td>
            ))
          ) : (
            <td>
              <EmptyValue variant="message" />
            </td>
          )}
        </tr>
      </tbody>
    </table>
    <div className="pb-2">
      <CountriesTable countries={productGroup.countries.nodes} />
    </div>
  </>
)

const MainPanel = ({ productGroup }: ProductGroupProps) => (
  <>
    <BorderBoxContainer>
      <BorderBoxHeading>Product Description</BorderBoxHeading>
      <BorderBox>
        <Field name="description" as={TextAreaWithFormikErrors} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Product Information</BorderBoxHeading>
      <BorderBox>
        <table className="table table-borderless nice-table">
          <tbody>
            <tr>
              <td className="pb-0 border-0 w-50">
                <span className="pr-2 text-uppercase">HTS Code:</span>
                <Field name="htsCode" as={SelectWithFormikErrors}>
                  {productGroup.htsCodeOptions.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Field>
              </td>
            </tr>
          </tbody>
        </table>
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Images</BorderBoxHeading>
      <BorderBox>
        <ProductImages images={productGroup.images} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Tech Pack</BorderBoxHeading>
      <BorderBox>
        <ProductTechPack techPack={productGroup.techPack} />
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Product Alias</BorderBoxHeading>
      <BorderBox>
        <div className="w-100">
          <div className="d-flex">
            <BorderBoxHeading>Name Aliases</BorderBoxHeading>
            <a
              className="ml-auto"
              href={productGroup.newProductGroupNameAliasUrl}
            >
              Add
            </a>
          </div>
          {productGroup.nameAliases.length > 0 ? (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {productGroup.nameAliases.map((nameAlias) => (
                  <tr key={nameAlias.id}>
                    <td>{nameAlias.name}</td>
                    <td>{nameAlias.createdAt}</td>
                    <td>
                      <TableRowActions {...nameAlias} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-muted mb-4">
              No Name Aliases have been added yet.
            </div>
          )}
          <div className="d-flex">
            <BorderBoxHeading>Style Number Aliases</BorderBoxHeading>
            <a
              className="ml-auto"
              href={productGroup.newProductGroupStyleNumberAliasUrl}
            >
              Add
            </a>
          </div>
          {productGroup.styleNumberAliases.length > 0 ? (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {productGroup.styleNumberAliases.map((styleNumberAlias) => (
                  <tr key={styleNumberAlias.id}>
                    <td>{styleNumberAlias.styleNumber}</td>
                    <td>{styleNumberAlias.createdAt}</td>
                    <td>
                      <TableRowActions {...styleNumberAlias} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-muted mb-4">
              No Style Number Aliases have been added yet.
            </div>
          )}
        </div>
      </BorderBox>
    </BorderBoxContainer>
  </>
)

export default withBasics(ProductGroupEdit)
