import React from "react"
import invariant from "tiny-invariant"
import { useProductProfileOrdersQuery } from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { ReactComponent as DownChevronSVG } from "../images/down-chevron.svg"
import { ReactComponent as EyeSVG } from "../images/eye.svg"
import { EmptyValue } from "./EmptyValue"
import VisuallyHidden from "@reach/visually-hidden"
import { BorderBox, BorderBoxEmpty } from "./BorderBox"
import numberWithCommas from "../util/number-with-commas"

const ProductOrderHistoryTable = (props: { id: string }) => {
  const result = useInfiniteGraphQLQuery(
    useProductProfileOrdersQuery,
    ({ pageParam }) => ({
      id: props.id,
      first: 15,
      after: pageParam,
    }),
    {
      getNextPageParam: (page) =>
        page.product.clientOrders.pageInfo.endCursor ?? undefined,
    },
  )

  if (result.status === "loading") return <LoadingIndicator />
  if (result.status === "error") return <RequestError {...result} />

  invariant(result.data, `expected data`)

  const clientOrders = result.data.pages.flatMap(
    (page) => page.product.clientOrders.nodes,
  )

  // TODO(AM): Figure out why result.hasNextPage isn't producing the same result
  const hasNextPage =
    result.data.pages[result.data.pages.length - 1].product.clientOrders
      .pageInfo.hasNextPage

  return (
    <>
      {clientOrders.length > 0 ? (
        <BorderBox>
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr className="text-center">
                  <th>Client Name</th>
                  <th>Client Project #</th>
                  <th>Units</th>
                  <th>Price</th>
                  <th>MSRP</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="f-small-text">
                {clientOrders.map((clientOrder) => (
                  <tr key={clientOrder.id}>
                    <td>{clientOrder.client.name}</td>
                    <td>
                      <a href={clientOrder.showUrl}>{clientOrder.humanId}</a>
                    </td>
                    <td>{numberWithCommas(clientOrder.totalQuantity)}</td>
                    <td>
                      {clientOrder.perItemPriceRange || (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      {clientOrder.msrpRange || <EmptyValue variant="dash" />}
                    </td>
                    <td>
                      <a
                        href={clientOrder.showUrl}
                        className="d-flex mr-2 text-dark-teal"
                      >
                        <EyeSVG style={{ height: 12 }} />
                        <VisuallyHidden>View client order</VisuallyHidden>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {hasNextPage && (
              <div className="pv-3 d-flex flex-grow-1 justify-content-center">
                <button
                  onClick={() => result.fetchNextPage()}
                  type="button"
                  className="btn btn-link f-action-text"
                  disabled={result.isFetchingNextPage}
                >
                  {result.isLoading ? (
                    "Loading..."
                  ) : (
                    <>
                      View More <DownChevronSVG className="ml-1" />
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No orders have been placed on this product yet.
        </BorderBoxEmpty>
      )}
    </>
  )
}

export default ProductOrderHistoryTable
