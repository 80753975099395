import { TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs"
import clsx from "clsx"
import React from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  useProductGroupShowQuery,
  ProductGroupShowMakerFragment,
  ProductGroupStatus,
  ModeOfTransit,
  useProductGroupProfilePricingAndLogisticsQuery,
} from "../generated/graphql"
import { formatDate } from "../util/dates"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
  BorderBoxItem,
} from "./BorderBox"
import EditLink from "./EditLink"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import { withBasics } from "./withBasics"
import { ImageWithControls } from "./LegacyImage"
import { StyledTab } from "./StyledTab"
import ProductOrderHistoryTable from "./ProductOrderHistoryTable"
import CountriesTable from "./CountriesTable"
import UnitValue from "./UnitValue"
import UnitLabel from "./UnitLabel"
import UnitToggle from "./UnitToggle"
import CogsTableSettings from "./CogsTableSettings"
import { ReactComponent as CopySVG } from "../images/copy.svg"
import VisuallyHidden from "@reach/visually-hidden"
import numberWithCommas from "../util/number-with-commas"

const ProductGroupProfile = (props: { id: string }) => {
  const result = useProductGroupShowQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const productGroup = result.data.productGroup

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-lg-row">
        <div className="mr-auto mb-2">
          <Header productGroup={productGroup} />
        </div>
        <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
          <Status productGroup={productGroup} />
        </div>
      </div>
      <div className="pb-4">
        <SubHeader productGroup={productGroup} />
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-4 mb-4">
          <div className="card border-light-teal">
            <div className="card-body">
              <LeftCard productGroup={productGroup} />
            </div>
          </div>
        </div>
        <div className="col col-md-12 col-lg-8">
          <div className="card border-light-teal">
            <div className="card-body">
              <RightCard productGroup={productGroup} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ProductGroupProps = {
  productGroup: ProductGroupShowMakerFragment
}

const productGroupStatusLabels: Readonly<Record<ProductGroupStatus, string>> = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
}

const Status = ({ productGroup }: ProductGroupProps) => (
  <>
    <div className="mb-2">
      <div>Last Updated: {formatDate(productGroup.updatedAt)}</div>
    </div>
    <div
      className="btn-group btn-group-sm"
      role="group"
      aria-label="Product Status"
    >
      {Object.values(ProductGroupStatus).map((status) => (
        <a
          key={status}
          href={productGroup.editUrl}
          className={clsx(
            "btn btn-sm",
            productGroup.status === status
              ? "btn-teal text-white"
              : "btn-outline-teal",
          )}
        >
          {productGroupStatusLabels[status]}
        </a>
      ))}
    </div>
  </>
)

const Header = ({ productGroup }: ProductGroupProps) => (
  <div className="mt-4 d-flex flex-row">
    <div className="d-flex flex-column">
      <h1
        className="mb-3"
        style={{
          fontSize: 40,
          lineHeight: `48px`,
          letterSpacing: "0.05em",
        }}
      >
        {productGroup.name}
      </h1>
    </div>
  </div>
)

const SubHeader = ({ productGroup }: ProductGroupProps) => (
  <>
    <table>
      <tbody>
        <tr>
          <td colSpan={2} className="font-weight-bold pr-4">
            ID {productGroup.humanId}
          </td>
        </tr>
        <tr>
          <td className="font-weight-bold pr-4">Product Categories</td>
          {productGroup.productCategories.length > 0 ? (
            productGroup.productCategories.map((productCategory, i) => (
              <td key={i} className="pr-4">
                {productCategory.name}
              </td>
            ))
          ) : (
            <td>
              <EmptyValue variant="message" />
            </td>
          )}
        </tr>
      </tbody>
    </table>
    <div className="pb-2">
      <CountriesTable countries={productGroup.countries.nodes} />
    </div>
  </>
)

const LeftCard = ({ productGroup }: ProductGroupProps) => (
  <>
    <div className="d-flex mb-4">
      <h5 className="flex-grow-1">{productGroup.maker.name}</h5>
      <div className="pl-2 ml-auto">
        <EditLink {...productGroup} />
      </div>
    </div>

    <p>
      Maker Country:{" "}
      {productGroup.makerCountries.length > 0 ? (
        productGroup.makerCountries
          .map((makerCountry) => makerCountry.name)
          .join(", ")
      ) : (
        <EmptyValue variant="message" />
      )}
    </p>

    <p>
      Maker Facing Name:{" "}
      {productGroup.makerName || <EmptyValue variant="message" />}
    </p>

    <BorderBoxContainer>
      <BorderBoxHeading>Shipping Ports</BorderBoxHeading>
      {productGroup.maker.shippingPorts.nodes.length > 0 ? (
        <BorderBox>
          {productGroup.maker.shippingPorts.nodes.map(({ port }) => (
            <BorderBoxItem key={port}>{port}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>No Maker Ports have been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Types</BorderBoxHeading>
      {productGroup.maker.makerTypes.length > 0 ? (
        <BorderBox>
          {productGroup.maker.makerTypes.map((makerType) => (
            <BorderBoxItem key={makerType}>{makerType}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>No Maker Types have been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Attributes</BorderBoxHeading>
      {productGroup.makerAttributes.length > 0 ? (
        <BorderBox>
          {productGroup.makerAttributes.map((value) => (
            <BorderBoxItem key={value.id}>{value.text}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Value Alignments have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Maker Environments</BorderBoxHeading>
      {productGroup.environments.length > 0 ? (
        <BorderBox>
          {productGroup.environments.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Environments have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Capabilities</BorderBoxHeading>
      {productGroup.maker.capabilities.length > 0 ? (
        <BorderBox>
          {productGroup.maker.capabilities.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Capabilities have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Certifications</BorderBoxHeading>
      {productGroup.makerCertificationTypes.length > 0 ? (
        <BorderBox>
          {productGroup.makerCertificationTypes.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Maker Certifications have been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Social Impact</BorderBoxHeading>
      {productGroup.maker.socialImpactDescription ? (
        <BorderBox>{productGroup.maker.socialImpactDescription}</BorderBox>
      ) : (
        <BorderBoxEmpty>No Social Impact has been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Environmental Impact</BorderBoxHeading>
      {productGroup.maker.environmentalImpactDescription ? (
        <BorderBox>
          {productGroup.maker.environmentalImpactDescription}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          No Environmental Impact has been added yet.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>
  </>
)

const RightCard = (props: ProductGroupProps) => (
  <div>
    <Tabs>
      <div className="d-flex flex-grow-1">
        <div className="ml-auto">
          <EditLink {...props.productGroup} />
        </div>
      </div>
      <div className="d-flex flex-grow-1 justify-content-center">
        <TabList>
          <StyledTab>Main</StyledTab>
          <StyledTab>Pricing & Logistics</StyledTab>
          <StyledTab>History</StyledTab>
        </TabList>
      </div>
      <TabPanels>
        <TabPanel>
          <MainPanel {...props} />
        </TabPanel>
        <TabPanel>
          <ProductGroupPricingAndLogisticsPanel id={props.productGroup.id} />
        </TabPanel>
        <TabPanel>
          <HistoryPanel {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
)

const MainPanel = ({ productGroup }: ProductGroupProps) => (
  <>
    <BorderBoxContainer>
      <BorderBoxHeading>Product Description</BorderBoxHeading>
      {productGroup.description ? (
        <BorderBox>{productGroup.description}</BorderBox>
      ) : (
        <BorderBoxEmpty>No Description has been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <BorderBoxHeading>Product Information</BorderBoxHeading>
      <BorderBox>
        <table className="table table-borderless nice-table">
          <tbody>
            <tr>
              <td className="pb-0 border-0 w-50">
                <span className="pr-2 text-uppercase">Production Method:</span>
                {productGroup.productionMethods.length > 0 ? (
                  productGroup.productionMethods.join(", ")
                ) : (
                  <EmptyValue variant="message" />
                )}
              </td>
              <td className="pb-0 border-0 w-50">
                <span className="pr-2 text-uppercase">HTS Code:</span>
                {productGroup.htsCode || <EmptyValue variant="message" />}
              </td>
            </tr>
          </tbody>
        </table>
      </BorderBox>
    </BorderBoxContainer>

    <BorderBoxContainer>
      <div className="d-flex align-items-baseline w-100">
        <BorderBoxHeading>Products</BorderBoxHeading>
        <a
          href={productGroup.newProductUrl}
          className="btn btn-sm text-dark-teal ml-auto"
        >
          Add
        </a>
      </div>
      {productGroup.products.length > 0 ? (
        <BorderBox>
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Description</th>
                  <th>Images</th>
                  <th>Price-Affecting Variants</th>
                  <th>Non-Price-Affecting Variants</th>
                  <th>Max Capacity (Units / Month)</th>
                  <th>Available Until</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productGroup.products.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <p className="mb-0">
                        <a href={product.showUrl}>{product.name}</a>
                      </p>
                      <p>{product.humanId}</p>
                    </td>
                    <td>
                      {product.description && <p>{product.description}</p>}
                      {product.materials.nodes.length > 0 && (
                        <p>
                          Materials:{" "}
                          {product.materials.nodes
                            .map((material) => material.name)
                            .join(", ")}
                        </p>
                      )}
                      {product.countryOfOrigin && (
                        <p>Made In {product.countryOfOrigin.name}.</p>
                      )}
                    </td>
                    <td>
                      <div className="d-flex overflow-x-auto">
                        {product.images.map((image) => (
                          <Imgix
                            key={image.id}
                            src={image.imgixUrl}
                            className="w-100 shadow m-1"
                            htmlAttributes={{
                              alt: "",
                              style: {
                                aspectRatio: `1 / 1`,
                              },
                            }}
                            sizes="100px"
                            imgixParams={{
                              "ar": `1:1`,
                              "fit": `fill`,
                              "fill-color": `white`,
                            }}
                          />
                        ))}
                      </div>
                    </td>
                    <td>
                      {product.variants.length > 0 ? (
                        <>
                          {product.variants.map((productVariant) => (
                            <p key={productVariant.id} className="mb-0">
                              {productVariant.name}
                            </p>
                          ))}
                        </>
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </td>
                    <td>
                      {product.npaVariants.length > 0 ? (
                        <>
                          {product.variantName}:{" "}
                          {product.npaVariants
                            .map((npaVariant) => npaVariant.name)
                            .join(", ")}
                        </>
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </td>
                    <td>
                      {numberWithCommas(product.maxCapacity) || (
                        <EmptyValue variant="message" />
                      )}
                    </td>
                    <td>
                      {product.availableUntil ? (
                        formatDate(product.availableUntil)
                      ) : (
                        <EmptyValue variant="message" />
                      )}
                    </td>
                    <td>
                      <a href={product.duplicateUrl}>
                        <CopySVG
                          style={{ width: 16, height: 16 }}
                          className="text-dark-teal"
                        />
                        <VisuallyHidden>Duplicate</VisuallyHidden>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </BorderBox>
      ) : (
        <BorderBoxEmpty>No Products have been added yet.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <div className="d-grid grid-cols-2 gap-4">
      <BorderBoxContainer>
        <BorderBoxHeading>Images</BorderBoxHeading>
        {productGroup.images.length > 0 ? (
          <BorderBox>
            <div className="d-grid grid-cols-3 gap-4 mb-2">
              {productGroup.images.map((image) => (
                <ImageWithControls
                  key={image.id}
                  {...image}
                  editUrl={productGroup.editUrl}
                />
              ))}
            </div>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>No Images have been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Tech Pack</BorderBoxHeading>
        {productGroup.techPack ? (
          <BorderBox>
            <a href={productGroup.techPack.url} className="text-dark-teal">
              {productGroup.techPack.filename}
            </a>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>No Tech Pack has been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>
    </div>

    <BorderBoxContainer>
      <BorderBoxHeading>Previous Buyers</BorderBoxHeading>
      {productGroup.previousBuyers.length > 0 ? (
        <BorderBox>
          {productGroup.previousBuyers.map((value) => (
            <BorderBoxItem key={value}>{value}</BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>There are no Previous Buyers.</BorderBoxEmpty>
      )}
    </BorderBoxContainer>

    <BorderBoxContainer>
      <div className="d-flex justify-content-between w-100">
        <BorderBoxHeading>Product Alias</BorderBoxHeading>
        <EditLink {...productGroup} />
      </div>
      <BorderBox>
        <div className="w-100">
          <BorderBoxHeading>Name Aliases</BorderBoxHeading>
          {productGroup.nameAliases.length > 0 ? (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {productGroup.nameAliases.map((nameAlias) => (
                  <tr key={nameAlias.id}>
                    <td>{nameAlias.name}</td>
                    <td>{nameAlias.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-muted mb-4">
              No Name Aliases have been added yet.
            </div>
          )}
          <BorderBoxHeading>Style Number Aliases</BorderBoxHeading>
          {productGroup.styleNumberAliases.length > 0 ? (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {productGroup.styleNumberAliases.map((styleNumberAlias) => (
                  <tr key={styleNumberAlias.id}>
                    <td>{styleNumberAlias.styleNumber}</td>
                    <td>{styleNumberAlias.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-muted mb-4">
              No Style Number Aliases have been added yet.
            </div>
          )}
        </div>
      </BorderBox>
    </BorderBoxContainer>
  </>
)

export const ProductGroupPricingAndLogisticsPanel = (props: { id: string }) => {
  const result = useProductGroupProfilePricingAndLogisticsQuery({
    id: props.id,
  })
  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { productGroup } = result.data

  return (
    <>
      <CogsTableSettings
        mode={"view"}
        makerId={productGroup.maker.id}
        productGroupId={productGroup.id}
        productId={null}
        defaultAirShippingPortId={productGroup.maker.defaultAirShippingPort?.id}
        defaultSeaShippingPortId={productGroup.maker.defaultSeaShippingPort?.id}
        otherAirShippingPortId={
          // seems wrong, but matches existing behavior
          productGroup.maker.shippingPorts.nodes.find(
            (x) => x.modeOfTransit === ModeOfTransit.Air,
          )?.id
        }
        otherSeaShippingPortId={
          // seems wrong, but matches existing behavior
          productGroup.maker.shippingPorts.nodes.find(
            (x) => x.modeOfTransit === ModeOfTransit.Sea,
          )?.id
        }
      />

      <BorderBoxContainer>
        <BorderBoxHeading>Platform IDs</BorderBoxHeading>
        <BorderBox>
          <div className="w-100 d-flex align-items-end flex-column">
            <UnitToggle />
          </div>

          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Platform ID</th>
                  <th>Size</th>
                  <th>HTS Code</th>
                  <th>
                    <UnitLabel label="Weight" unitName="mass" />
                  </th>
                  <th>
                    <UnitLabel label="Height" unitName="length" />
                  </th>
                  <th>
                    <UnitLabel label="Width" unitName="length" />
                  </th>
                  <th>
                    <UnitLabel label="Length" unitName="length" />
                  </th>
                  <th>Shipping Price By</th>
                </tr>
              </thead>
              <tbody className="f-small-text">
                {productGroup.products
                  .map((product) => product.skus)
                  .flat()
                  .map((sku) => (
                    <tr key={sku.id}>
                      <td>{sku.humanId}</td>
                      <td>{sku.variant.name}</td>
                      <td>
                        {sku.variant.htsCode || <EmptyValue variant="dash" />}
                      </td>
                      <td>
                        <UnitValue
                          value={sku.variant.weightKg || 0}
                          unit="kg"
                          unitName="mass"
                        />
                      </td>
                      <td>
                        <UnitValue
                          value={sku.variant.heightCm || 0}
                          unit="cm"
                          unitName="length"
                        />
                      </td>
                      <td>
                        <UnitValue
                          value={sku.variant.widthCm || 0}
                          unit="cm"
                          unitName="length"
                        />
                      </td>
                      <td>
                        <UnitValue
                          value={sku.variant.lengthCm || 0}
                          unit="cm"
                          unitName="length"
                        />
                      </td>
                      <td>
                        {sku.variant.shippingPriceBy || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const HistoryPanel = ({ productGroup }: ProductGroupProps) => (
  <>
    {productGroup.products.map((product) => (
      <BorderBoxContainer key={product.id}>
        <BorderBoxHeading>{product.name}</BorderBoxHeading>
        <ProductOrderHistoryTable id={product.id} />
      </BorderBoxContainer>
    ))}
  </>
)

export default withBasics(ProductGroupProfile)
