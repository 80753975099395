import React, { useState } from "react"
import { withBasics } from "./withBasics"
import { RequestError } from "./Errors"
import LoadingIndicator from "./LoadingIndicator"
import invariant from "tiny-invariant"
import MakerOrderHistoryModal from "./MakerOrderHistoryModal"
import { EmptyValue } from "./EmptyValue"
import {
  UpdateMakerOrderInput,
  UpdateMakerOrderMutation,
  useMakerOrderShowQuery,
  useUpdateMakerOrderMutation,
} from "../generated/graphql"
import { BorderBox, BorderBoxContainer, BorderBoxHeading } from "./BorderBox"
import { FormatCentsMaybe } from "./Currency"
import { ClientDetailsAddress } from "../util/addresses"
import TimeAndAction from "./TimeAndAction"
import { formatDate, formatDateLong, formatDateTimeLong } from "../util/dates"
import DocumentActions from "./DocumentActions"
import MakerOrderShipmentDeliveredOnModal from "./MakerOrderShipmentDeliveredOnModal"
import ContactsSection from "./ContactsSection"
import MakerOrderPendingModal from "./MakerOrderPendingModal"

type MakerOrderProfileProps = {
  id: string
  lockedView: boolean
}

const MakerOrderProfile = ({ id, lockedView }: MakerOrderProfileProps) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false)
  const [pendingModalOpen, setPendingModalOpen] = useState(false)
  const result: any = useMakerOrderShowQuery({ id: id })
  const mutation = useUpdateMakerOrderMutation()
  const [
    shipmentStatusDeliveredModalOpen,
    setshipmentStatusDeliveredModalOpen,
  ] = useState(false)

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const makerOrder = result.data.makerOrder

  const updateMakerOrder = async (changes: any) => {
    const input: UpdateMakerOrderInput = {
      id: makerOrder.id,
      ...changes,
      shipment: {
        id: makerOrder.shipment?.id,
        ...changes.shipment,
      },
    }

    try {
      const {
        updateMakerOrder: result,
      }: UpdateMakerOrderMutation = await mutation.mutateAsync({
        input,
      })

      if (result && result.errors.length) {
        console.log(result)
      } else {
        window.location.href = makerOrder.showUrl + (window.location.hash || "")
      }
    } catch (er) {
      window.alert(`An error occured: ${(er as Error).message}`)
    }
  }

  const updateMakerOrderShipmentStatus = async (status: any) => {
    if (status === "delivered") {
      setshipmentStatusDeliveredModalOpen(true)
    } else {
      updateMakerOrder({ shipmentStatus: status })
    }
  }

  return (
    <>
      <MakerOrderPendingModal
        makerOrder={makerOrder}
        isFormik={false}
        isOpen={pendingModalOpen}
        onClose={() => setPendingModalOpen(false)}
        onSubmit={updateMakerOrder}
      />

      <MakerOrderHistoryModal
        isOpen={historyModalOpen}
        onClose={() => setHistoryModalOpen(false)}
        makerOrderId={makerOrder.id}
      />

      <MakerOrderShipmentDeliveredOnModal
        isOpen={shipmentStatusDeliveredModalOpen}
        onClose={() => setshipmentStatusDeliveredModalOpen(false)}
        updateMakerOrder={updateMakerOrder}
      />

      <div className="form-row align-items-center mb-4">
        <div className="form-row align-items-center">
          <h6 className="col-auto w5">APPROVAL STATUS</h6>
          <div className="col-auto w5">
            <select
              className="form-control"
              name="MakerOrderStatusSelect"
              data-testid="maker-order-view-history"
              value={makerOrder.status || ""}
              onChange={(e) => {
                if (e.target.value === "pending") {
                  setPendingModalOpen(true)
                } else if (
                  e.target.value === "not_shared" &&
                  makerOrder.shared
                ) {
                  alert(
                    "This Maker order has already been shared with the maker.",
                  )
                } else {
                  updateMakerOrder({ status: e.target.value })
                }
              }}
            >
              {makerOrder.statusOptions.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-auto ml-auto text-right">
          <div className="d-block">
            {`${
              makerOrder.updatedAt !== makerOrder.createdAt
                ? "Revised"
                : "Created"
            } On: `}
            {formatDateTimeLong(makerOrder.updatedAt || makerOrder.createdAt)}
          </div>
          <div className="d-block">
            <div
              className="btn btn-sm btn-link"
              data-testid="maker-order-view-history"
              onClick={() => setHistoryModalOpen(true)}
            >
              View History
            </div>
          </div>
          <div className="d-block">
            <a
              href={makerOrder.editUrl}
              className="btn btn-link btn-sm text-dark-teal"
            >
              Edit
            </a>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-4">
        <div className="form-row align-items-center">
          <h6 className="col-auto w5">PRODUCTION STATUS</h6>
          <div className="col-auto w5">
            {makerOrder.productionStatusReadable || (
              <a href={makerOrder.newTnaUrl} className="btn btn-md btn-primary">
                Create TnA
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="form-row align-items-center  mb-4">
        <h6 className="col-auto w5">SHIPMENT STATUS</h6>
        <div className="col-auto w5">
          <select
            className="form-control"
            name="MakerOrderStatusSelect"
            value={makerOrder.shipmentStatus || ""}
            onChange={(e) => {
              updateMakerOrderShipmentStatus(e.target.value)
            }}
          >
            {makerOrder.shipmentStatusOptions.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-row align-items-center">
        <h6 className="col-auto w5">DELIVERED ON</h6>
        <div className="col-auto w5">
          {makerOrder.shipment?.deliveredOn ? (
            formatDateLong(makerOrder.shipment.deliveredOn)
          ) : (
            <EmptyValue variant="message" />
          )}
        </div>
      </div>

      <BorderBoxContainer>
        <BorderBoxHeading>Order Details</BorderBoxHeading>
        <BorderBox solid={true}>
          <div className="row w-100">
            <div className="col-lg-6">
              <div className="mb-3">
                <strong className="d-inline-block w5">Client Project:</strong>
                <a
                  href={makerOrder.clientOrder.showUrl}
                >{`#${makerOrder.clientOrder.humanId} ${makerOrder.clientOrder.description}`}</a>
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">Issued To:</strong>
                {makerOrder.maker.name}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">Platform IDs:</strong>
                {makerOrder.productCount}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">Total Units:</strong>
                {makerOrder.totalUnits}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">Total Cost:</strong>
                {<FormatCentsMaybe cents={(makerOrder.totalCost || 0) * 100} />}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">
                  Countries of Origin:
                </strong>
                {makerOrder.countriesOfOrigin
                  ?.map((c: any) => c.name)
                  .join(", ") || <EmptyValue variant="message" />}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3">
                <strong className="d-inline-block w5">Ex Factory Date:</strong>
                {formatDateLong(makerOrder.exFactoryDate) || (
                  <EmptyValue variant="message" />
                )}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">MO Cut Date:</strong>
                {formatDateLong(makerOrder.moCutDate) || (
                  <EmptyValue variant="message" />
                )}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">Incoterm:</strong>
                {makerOrder.incoterm?.name || <EmptyValue variant="message" />}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">
                  Maker Payment Terms:
                </strong>
                {makerOrder.paymentTerms || <EmptyValue variant="message" />}
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Contacts</BorderBoxHeading>
        <BorderBox solid={true}>
          <ContactsSection order={makerOrder} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Customer Information</BorderBoxHeading>
        <BorderBox solid={true}>
          <div className="row w-100">
            <div className="col-lg-6">
              <div className="mb-3">
                <strong className="d-inline-block w5">Customer:</strong>
                {makerOrder.client.name}
              </div>

              <div className="mb-3">
                <strong className="d-inline-block w5">
                  Maker can see client:
                </strong>
                {makerOrder.clientInfoVisibleToMaker ? "Yes" : "No"}
              </div>

              <div className="d-flex align-self-start mb-3">
                <strong className="d-inline-block w5">Ship To:</strong>
                <div className="d-inline-block w5">
                  {makerOrder.clientOrder.shippingAddress ? (
                    <ClientDetailsAddress
                      address={makerOrder.clientOrder.shippingAddress}
                    />
                  ) : (
                    <EmptyValue variant="message" />
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3">
                <strong className="d-inline-block w5">
                  Billing/Consignee Address:
                </strong>
                {makerOrder.billingConsigneeAddress ? (
                  <ClientDetailsAddress
                    address={makerOrder.billingConsigneeAddress}
                  />
                ) : (
                  <EmptyValue variant="message" />
                )}
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      {makerOrder.timeAndAction ? (
        <TimeAndAction
          id={makerOrder.timeAndAction.id}
          editing={false}
          locked={lockedView}
        />
      ) : (
        <BorderBoxContainer>
          <div className="d-flex align-items-baseline justify-content-between w-100">
            <BorderBoxHeading>{"Time And Action"}</BorderBoxHeading>

            <div className="d-flex align-items-center">
              <div className="d-block">
                <a
                  href={`/client-projects/${makerOrder.clientOrder.humanId}/time_and_actions/new`}
                  className="btn btn-link btn-sm text-dark-teal"
                >
                  Add
                </a>
              </div>
            </div>
          </div>

          <BorderBox solid={true}>
            No Time and Action created for this maker order yet.
          </BorderBox>
        </BorderBoxContainer>
      )}

      <BorderBoxContainer>
        <BorderBoxHeading>Production Details</BorderBoxHeading>
        <BorderBox solid={true}>
          <div className="d-flex flex-column w-100">
            <div className="mb-4">
              <strong className="d-block">Production Notes</strong>
              <textarea
                className="form-control"
                disabled
                data-controller="autosize-textarea"
              >
                {makerOrder.productionNotes}
              </textarea>
            </div>

            <div className="mb-4">
              <strong className="d-block">Packing Notes</strong>
              <textarea
                className="form-control"
                disabled
                data-controller="autosize-textarea"
              >
                {makerOrder.trackingInfo}
              </textarea>
            </div>
            <div className="mb-4">
              <strong className="d-block">Shipping Notes</strong>
              <textarea
                className="form-control"
                disabled
                data-controller="autosize-textarea"
              >
                {makerOrder.packingAndShippingNotes}
              </textarea>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Shipment Details</BorderBoxHeading>
        <div className="border rounded border-light-teal px-3 pt-3 pb-2 overflow-x-auto">
          {makerOrder.shipment ? (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">Shipping Mode</strong>
                    <select
                      className="form-control"
                      style={{ maxWidth: "250px" }}
                      name="MakerOrderShipmentShippingModeSelect"
                      value={makerOrder.shipment?.shippingMode || ""}
                      onChange={(e) => {
                        updateMakerOrder({
                          shipment: { shippingMode: e.target.value },
                        })
                      }}
                    >
                      {makerOrder.shippingModeOptions.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <strong className="d-inline-block w5">Export Port</strong>
                    {makerOrder.shipment.departureShippingPort?.port || (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <strong className="d-inline-block w5">Import Port</strong>
                    {makerOrder.shipment.arrivalShippingPort?.port || (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">
                      Export Port Departure
                    </strong>
                    {makerOrder.shipment.departingPortAt ? (
                      formatDateLong(makerOrder.shipment.departingPortAt)
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">
                      Import Port Arrival
                    </strong>
                    {makerOrder.shipment.arrivingPortAt ? (
                      formatDateLong(makerOrder.shipment.arrivingPortAt)
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">Vessel Status</strong>
                    <select
                      className="form-control"
                      style={{ maxWidth: "250px" }}
                      name="MakerOrderShipmentVesselStatusSelect"
                      value={makerOrder.shipment?.vesselStatus || ""}
                      onChange={(e) => {
                        updateMakerOrder({
                          shipment: { vesselStatus: e.target.value },
                        })
                      }}
                    >
                      {makerOrder.vesselStatusOptions.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">
                      Customs Status
                    </strong>
                    <select
                      className="form-control"
                      style={{ maxWidth: "250px" }}
                      name="MakerOrderShipmentCustomsStatusSelect"
                      value={makerOrder.shipment?.customsStatus || ""}
                      onChange={(e) => {
                        updateMakerOrder({
                          shipment: { customsStatus: e.target.value },
                        })
                      }}
                    >
                      {makerOrder.customsStatusOptions.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">
                      Final Mile Delivery
                    </strong>
                    <select
                      className="form-control"
                      style={{ maxWidth: "250px" }}
                      name="MakerOrderShipmentFinalMileDeliverySelect"
                      value={makerOrder.shipment?.finalMileDelivery || ""}
                      onChange={(e) => {
                        updateMakerOrder({
                          shipment: { finalMileDelivery: e.target.value },
                        })
                      }}
                    >
                      {makerOrder.finalMileDeliveryOptions.map(
                        (option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">Delivered On</strong>
                    {makerOrder.shipment.deliveredOn ? (
                      formatDateLong(makerOrder.shipment.deliveredOn)
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <strong className="d-inline-block w5">
                      Parcel Carrier
                    </strong>
                    {makerOrder.shipment.parcelCarrier ? (
                      makerOrder.shipment.parcelCarrier.name
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex mb-3 display-linebreak">
                    <strong className="d-inline-block w5">
                      Tracking Numbers
                    </strong>
                    {makerOrder.shipment.trackingNumbers ? (
                      makerOrder.shipment.trackingNumbers
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column" style={{ padding: "25px" }}>
                <div id="shippingdocs" className="d-flex w-100">
                  <BorderBoxHeading>Shipment Documents</BorderBoxHeading>
                  {makerOrder.shipment && (
                    <a
                      className="ml-auto text-dark-teal"
                      href={makerOrder.shipment?.newShipmentDocumentUrl}
                    >
                      Add
                    </a>
                  )}
                </div>

                {makerOrder.shipment?.shipmentDocuments.length === 0 ? (
                  <BorderBox>
                    <EmptyValue variant="message" />
                  </BorderBox>
                ) : (
                  <BorderBox>
                    <table className="table nice-table">
                      <thead>
                        <tr>
                          {["Date", "Type", "File Name", "Actions"].map(
                            (heading) => (
                              <th key={heading}>{heading}</th>
                            ),
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {makerOrder.shipment?.shipmentDocuments.map(
                          (doc: any) => (
                            <tr key={doc.id}>
                              <td>{formatDate(doc.createdAt)}</td>
                              <td>{doc.documentType}</td>
                              <td>{doc.fileName}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <DocumentActions document={doc} />
                                </div>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </BorderBox>
                )}
              </div>
            </>
          ) : (
            <p>No shipment details set for this maker order.</p>
          )}
        </div>
      </BorderBoxContainer>
    </>
  )
}

export default withBasics(MakerOrderProfile)
