import React from "react"
import { withBasics } from "./withBasics"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { DownloadAttachmentFragment } from "../generated/graphql"
import VisuallyHidden from "@reach/visually-hidden"
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button"

const FileDownloadDropdown = ({
  attachments,
}: {
  attachments: Array<DownloadAttachmentFragment>
}) => {
  return (
    <>
      {attachments.length === 1 ? (
        <a
          className="d-flex p-1 align-items-center text-dark-teal"
          href={attachments[0].downloadUrl}
        >
          <DownloadSVG />

          <div className="small ml-1">({attachments.length})</div>
          <VisuallyHidden>Download document</VisuallyHidden>
        </a>
      ) : attachments.length > 1 ? (
        <Menu>
          <MenuButton className="border-0 bg-transparent d-flex p-1 align-items-center text-dark-teal">
            <DownloadSVG />
            <div className="small ml-1">({attachments.length})</div>
            <VisuallyHidden>Download document</VisuallyHidden>
          </MenuButton>
          <MenuList>
            {attachments.map((attachment) => (
              <MenuLink
                key={attachment.downloadUrl}
                as="a"
                href={attachment.downloadUrl}
              >
                {attachment.filename}
              </MenuLink>
            ))}
          </MenuList>
        </Menu>
      ) : null}
    </>
  )
}

export default withBasics(FileDownloadDropdown)
