import { Field, FormikProvider, useFormik } from "formik"
import React, { useState } from "react"
import invariant from "tiny-invariant"
import {
  MakerOrderEditMakerFragment,
  UpdateMakerOrderInput,
  UpdateMakerOrderMutation,
  useMakerOrderEditQuery,
  useUpdateMakerOrderMutation,
} from "../generated/graphql"
import { deepChangedValues } from "../util/changed-values"
import { EmptyValue } from "./EmptyValue"
import { RequestError } from "./Errors"
import { Button, Label } from "./FormElements"
import LoadingIndicator from "./LoadingIndicator"
import ContactsFormSection from "./ContactsFormSection"
import MakerOrderPendingModal from "./MakerOrderPendingModal"
import { withBasics } from "./withBasics"
import {
  CheckboxWithFormikErrors,
  DateInputWithFormikErrors,
  SelectWithFormikErrors,
  TextAreaWithFormikErrors,
  InputWithFormikErrors,
} from "./WithFormikErrors"
import { BorderBox, BorderBoxHeading } from "./BorderBox"
import { formatDate } from "../util/dates"
import DocumentActions from "./DocumentActions"

type MakerOrderProps = {
  makerOrder: MakerOrderEditMakerFragment
}

const MakerOrderEdit = (props: { id: string }) => {
  const result = useMakerOrderEditQuery({ id: props.id })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const makerOrder = result.data.makerOrder

  return <MakerOrderEditForm makerOrder={makerOrder} />
}

const MakerOrderEditForm = ({ makerOrder }: MakerOrderProps) => {
  const mutation = useUpdateMakerOrderMutation()
  const [pendingModalOpen, setPendingModalOpen] = useState(false)
  const [
    legalAgreementPendingModalOpen,
    setLegalAgreementPendingModalOpen,
  ] = useState(false)

  const [
    updateLegalAgreementUpdating,
    setUpdateLegalAgreementUpdating,
  ] = useState(false)

  const [
    updateLegalAgreementUpdated,
    setUpdateLegalAgreementUpdated,
  ] = useState(false)

  const redirectToMakerOrderProfile = () => {
    window.location.href = makerOrder.showUrl + (window.location.hash || "")
  }

  const formik = useFormik({
    initialValues: makerOrder,
    onSubmit: async (values: any, { setFieldValue, setFieldError }) => {
      const params: any = deepChangedValues(makerOrder, values)

      // TODO(AM): Show the lock modal only if fields from the Order have been changed
      if (
        makerOrder.status !== "not_shared" &&
        Object.keys(params).length > 0 &&
        editingMoreThanShippingDetails(params) &&
        !pendingModalOpen
      ) {
        setFieldValue("status", "pending")
        setPendingModalOpen(true)
        return
      }

      const input: UpdateMakerOrderInput = {
        id: makerOrder.id,
        ...params,
      }

      try {
        const {
          updateMakerOrder: result,
        }: UpdateMakerOrderMutation = await mutation.mutateAsync({
          input,
        })

        if (result && result.errors.length) {
          for (let error of result.errors)
            if (error.path) setFieldError(error.path[1], error.message)
        } else {
          redirectToMakerOrderProfile()
        }
      } catch (er) {
        window.alert(`An error occured: ${(er as Error).message}`)
      }
    },
  })

  const editingMoreThanShippingDetails = (params: any) => {
    const keys = Object.keys(params)
    const otherKeysExist = keys.some((key) => key !== "shipment")
    return otherKeysExist
  }

  const openLegalAgreementPendingModal = () => {
    formik.setFieldValue("status", "pending")
    setLegalAgreementPendingModalOpen(true)
  }

  const updateLegalAgreement = async () => {
    if (
      window.confirm(
        "Are you sure you want to update the legal agreements to match the current version found in Settings?",
      )
    ) {
      setLegalAgreementPendingModalOpen(false)
      setUpdateLegalAgreementUpdating(true)
      setUpdateLegalAgreementUpdated(false)

      const {
        updateMakerOrder: result,
      }: UpdateMakerOrderMutation = await mutation.mutateAsync({
        input: {
          id: makerOrder.id,
          copyLegalAgreementContentBeforeSave: true,
          makerOrderNotes: formik.values.makerOrderNotes,
          makerLegalAgreementId: formik.values.makerLegalAgreementId,
        },
      })

      if (result) {
        setUpdateLegalAgreementUpdating(false)
        setUpdateLegalAgreementUpdated(true)
      }
    }
  }

  const shippingAddress = makerOrder.clientOrder.shippingAddress
  const billingConsigneeAddressId =
    makerOrder.billingConsigneeAddress && makerOrder.billingConsigneeAddress.id

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <MakerOrderPendingModal
          makerOrder={makerOrder}
          isOpen={pendingModalOpen}
          isFormik={true}
          onClose={() => setPendingModalOpen(false)}
          onSubmit={formik.handleSubmit}
        />

        <MakerOrderPendingModal
          makerOrder={makerOrder}
          isOpen={legalAgreementPendingModalOpen}
          isFormik={true}
          onClose={() => setLegalAgreementPendingModalOpen(false)}
          onSubmit={updateLegalAgreement}
        />

        <div className="d-flex w-100">
          <h1 className="mb-3">{makerOrder.label}</h1>
          <div className="ml-auto">
            <Button
              variant="secondary"
              className="mr-3"
              onClick={redirectToMakerOrderProfile}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Legal Agreement</div>

          <div className="card-body">
            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_maker_legal_agreement_id"
              >
                Maker legal agreement
              </label>
              <div className="w-100">
                <Field
                  name="makerLegalAgreementId"
                  id="maker_order_maker_legal_agreement_id"
                  as={SelectWithFormikErrors}
                >
                  <option></option>
                  {makerOrder.makerLegalAgreementOptions.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Field>
                <a
                  href={makerOrder.makerLegalAgreementsUrl}
                  className="form-text f-small-text"
                >
                  Manage Legal Agreements
                </a>
              </div>

              <div>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={openLegalAgreementPendingModal}
                >
                  Update
                </Button>
              </div>

              {(updateLegalAgreementUpdating ||
                updateLegalAgreementUpdated) && (
                <div className="mt-2 ml-2">
                  {updateLegalAgreementUpdating && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  {updateLegalAgreementUpdated && (
                    <div className="text-success text-weight-bold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="bi bi-check-circle-fill text-success text-weight-bold"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Order Details</div>
          <div className="card-body">
            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_ex_factory_date"
              >
                Ex Factory Date
              </label>
              <div className="w-100">
                <Field
                  name="exFactoryDate"
                  id="maker_order_ex_factory_date"
                  as={DateInputWithFormikErrors}
                />
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_mo_cut_date"
              >
                MO Cut Date
              </label>
              <div className="w-100">
                <Field
                  name="moCutDate"
                  id="maker_order_mo_cut_date"
                  as={DateInputWithFormikErrors}
                />
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_incoterm_id"
              >
                Incoterm
              </label>
              <div className="w-100">
                <Field
                  name="incotermId"
                  id="maker_order_incoterm_id"
                  as={SelectWithFormikErrors}
                >
                  <option></option>
                  {makerOrder.incotermOptions.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Field>
                <a
                  href={makerOrder.incotermsUrl}
                  className="form-text f-small-text"
                >
                  Manage incoterms
                </a>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_payment_terms"
              >
                Maker Payment Terms
              </label>
              <div className="w-100">
                <Field
                  name="paymentTerms"
                  id="maker_order_payment_terms"
                  as={TextAreaWithFormikErrors}
                />
              </div>
            </div>

            <ContactsFormSection
              formik={formik}
              contactOptions={makerOrder.contactOptions}
              initialContacts={makerOrder.contacts}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Customer Information</div>

          <div className="card-body d-flex">
            <div className="col-6">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <strong className="d-inline-block w5">Customer</strong>
                  {makerOrder.client.name}
                </div>
              </div>
              <div className="form-group">
                <Field
                  name="clientInfoVisibleToMaker"
                  id="maker_order_client_info_visible_to_maker"
                  as={CheckboxWithFormikErrors}
                />
                <label htmlFor="maker_order_client_info_visible_to_maker">
                  Client info visible to maker
                </label>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <strong>Ship To</strong>
                  <div>
                    {shippingAddress ? (
                      <>
                        <small className="text-muted">
                          {shippingAddress.label}
                        </small>

                        <div>{shippingAddress.plain}</div>

                        {shippingAddress.contactName && (
                          <small>
                            <strong>Contact</strong>
                            {shippingAddress.contactName}
                          </small>
                        )}
                        {shippingAddress.contactPhone && (
                          <small>
                            <strong>Phone</strong>
                            {shippingAddress.contactPhone}
                          </small>
                        )}
                      </>
                    ) : (
                      <EmptyValue variant="message" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <Label
                htmlFor="billing_consignee_address_id"
                className="font-weight-bold"
              >
                Billing/Consignee Address
              </Label>
              <Field
                name="billingConsigneeAddressId"
                id="billing_consignee_address_id"
                as={SelectWithFormikErrors}
              >
                <option></option>
                <option value="">New Billing/Consignee Address</option>
                {makerOrder.billingConsigneeAddressOptions.map(
                  ({ id, label, plain }) => (
                    <option
                      value={id}
                      key={id}
                      selected={id === billingConsigneeAddressId}
                    >
                      {label} - {plain}
                    </option>
                  ),
                )}
              </Field>
              {/*to do*/}
              {formik.values.billingConsigneeAddressId === "" && (
                <>
                  <div>
                    <Label>Address Name</Label>
                    <Field
                      name="billingConsigneeAddressLabel"
                      id="billing_consignee_address_label"
                      as={InputWithFormikErrors}
                    />
                  </div>
                  <div>
                    <Label>Address</Label>
                    <Field
                      name="billingConsigneeAddressPlain"
                      id="billing_consignee_address_plain"
                      as={InputWithFormikErrors}
                    />
                  </div>
                  <div>
                    <Label>Contact Name</Label>
                    <Field
                      name="billingConsigneeAddressContactName"
                      id="billing_consignee_address_contact_name"
                      as={InputWithFormikErrors}
                    />
                  </div>
                  <div>
                    <Label>Contact Phone</Label>
                    <Field
                      name="billingConsigneeAddressContactPhone"
                      id="billing_consignee_address_contact_phone"
                      as={InputWithFormikErrors}
                    />
                  </div>
                </>
              )}
              <a href={makerOrder.addressesUrl}>Manage addresses</a>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Production Details</div>

          <div className="card-body">
            <div className="row mb-2">
              <div className="col-lg-6">
                <div className="form-group">
                  <label
                    className="w5 col-form-label font-weight-bold"
                    htmlFor="maker_order_production_notes"
                  >
                    Production Notes
                  </label>
                  <Field name="productionNotes" as={TextAreaWithFormikErrors} />
                </div>
                <div className="form-group">
                  <label
                    className="w5 col-form-label font-weight-bold"
                    htmlFor="maker_order_packing_notes"
                  >
                    Packing Notes
                  </label>
                  <Field
                    name="trackingInfo"
                    id="maker_order_packing_notes"
                    as={TextAreaWithFormikErrors}
                  />
                </div>
                <div className="form-group">
                  <label
                    className="w5 col-form-label font-weight-bold"
                    htmlFor="maker_order_shipping_notes"
                  >
                    Shipping Notes
                  </label>
                  <Field
                    name="packingAndShippingNotes"
                    id="maker_order_shipping_notes"
                    as={TextAreaWithFormikErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-header">Shipping Details</div>
          <div className="card-body d-flex flex-column">
            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="maker_order_ex_factory_date"
              >
                Shipping Mode
              </label>
              <div className="w-100">
                <Field
                  name="shipment.shippingMode"
                  id="shipment.shippingMode"
                  as={SelectWithFormikErrors}
                  defaultValue={makerOrder.shipment?.shippingMode || ""}
                >
                  <option value="" />
                  {makerOrder.shippingModeOptions.map((option: any) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentDepartureShippingPortId"
              >
                Export Port
              </label>
              <div className="w-100">
                <Field
                  name="shipment.departureShippingPortId"
                  id="shipmentDepartureShippingPortId"
                  as={SelectWithFormikErrors}
                  defaultValue={
                    makerOrder.shipment?.departureShippingPort?.id || null
                  }
                >
                  <option value="" />
                  {makerOrder.exportShippingPortOptions.map(({ id, port }) => (
                    <option value={id} key={id}>
                      {port}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentDepartingPortAt"
              >
                Export Port Departure
              </label>
              <div className="w-100">
                <Field
                  id="shipmentDepartingPortAt"
                  name="shipment.departingPortAt"
                  as={DateInputWithFormikErrors}
                />
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentArrivalShippingPortId"
              >
                Import Port
              </label>
              <div className="w-100">
                <Field
                  name="shipment.arrivalShippingPortId"
                  id="shipmentArrivalShippingPortId"
                  as={SelectWithFormikErrors}
                  defaultValue={
                    makerOrder.shipment?.arrivalShippingPort?.id || null
                  }
                >
                  <option value="" />
                  {makerOrder.importShippingPortOptions.map(({ id, port }) => (
                    <option value={id} key={id}>
                      {port}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentArrivingPortAt"
              >
                Import Port Arrival
              </label>
              <div className="w-100">
                <Field
                  id="shipmentArrivingPortAt"
                  as={DateInputWithFormikErrors}
                  name="shipment.arrivingPortAt"
                />
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentVesselStatus"
              >
                Vessel Status
              </label>
              <div className="w-100">
                <Field
                  name="shipment.vesselStatus"
                  id="shipmentVesselStatus"
                  as={SelectWithFormikErrors}
                  defaultValue={makerOrder.shipment?.vesselStatus || ""}
                >
                  <option value="" />
                  {makerOrder.vesselStatusOptions.map((option: any) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentCustomsStatus"
              >
                Customs Status
              </label>
              <div className="w-100">
                <Field
                  name="shipment.customsStatus"
                  id="shipmentCustomsStatus"
                  as={SelectWithFormikErrors}
                  defaultValue={makerOrder.shipment?.customsStatus || ""}
                >
                  <option value="" />
                  {makerOrder.customsStatusOptions.map((option: any) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentFinalMileDelivery"
              >
                Final Mile Delivery
              </label>
              <div className="w-100">
                <Field
                  name="shipment.finalMileDelivery"
                  id="shipmentFinalMileDelivery"
                  as={SelectWithFormikErrors}
                  defaultValue={makerOrder.shipment?.finalMileDelivery || ""}
                >
                  <option value="" />
                  {makerOrder.finalMileDeliveryOptions.map((option: any) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentDeliveredOn"
              >
                Delivered On
              </label>
              <div className="w-100">
                <Field
                  id="shipmentDeliveredOn"
                  as={DateInputWithFormikErrors}
                  name="shipment.deliveredOn"
                />
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentParcelCarrierId"
              >
                Parcel Carrier
              </label>
              <div className="w-100">
                {makerOrder.parcelCarrierOptions.length > 0 ? (
                  <Field
                    name="shipment.parcelCarrierId"
                    id="shipmentParcelCarrierId"
                    as={SelectWithFormikErrors}
                    defaultValue={
                      makerOrder.shipment?.parcelCarrier?.id || null
                    }
                  >
                    <option value="" />
                    {makerOrder.parcelCarrierOptions.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                ) : (
                  <a
                    href={makerOrder.makerShowUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Manage Options in Maker Profile
                  </a>
                )}
              </div>
            </div>

            <div className="form-group d-flex">
              <label
                className="w5 col-form-label font-weight-bold"
                htmlFor="shipmentTrackingNumbers"
              >
                Tracking Numbers
              </label>
              <div className="w-100">
                <Field
                  name="shipment.trackingNumbers"
                  id="shipmentTrackingNumbers"
                  as={TextAreaWithFormikErrors}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column" style={{ padding: "25px" }}>
            <div className="d-flex w-100">
              <BorderBoxHeading>Shipment Documents</BorderBoxHeading>
              {makerOrder.shipment && (
                <a
                  className="ml-auto text-dark-teal"
                  href={makerOrder.shipment?.newShipmentDocumentUrl}
                >
                  Add
                </a>
              )}
            </div>

            {makerOrder.shipment ? (
              <>
                {makerOrder.shipment?.shipmentDocuments.length === 0 ? (
                  <BorderBox>
                    <EmptyValue variant="message" />
                  </BorderBox>
                ) : (
                  <BorderBox>
                    <table className="table nice-table">
                      <thead>
                        <tr>
                          {["Date", "Type", "File Name", "Actions"].map(
                            (heading) => (
                              <th key={heading}>{heading}</th>
                            ),
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {makerOrder.shipment?.shipmentDocuments.map((doc) => (
                          <tr key={doc.id}>
                            <td>{formatDate(doc.createdAt)}</td>
                            <td>{doc.documentType}</td>
                            <td>{doc.fileName}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <DocumentActions document={doc} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </BorderBox>
                )}
              </>
            ) : (
              <BorderBox>
                <p>
                  Please first add at least one shipment detail above to create
                  a shipment and then be able to add files.
                </p>
              </BorderBox>
            )}
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

export default withBasics(MakerOrderEdit)
