import { Disclosure, DisclosurePanel } from "@reach/disclosure"
import React, { useState } from "react"
import { useDebounce } from "use-debounce"
import ActiveClientOrderContext from "../contexts/ActiveClientOrderContext"
import { ProductSort, useProductSearchQuery } from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import useParams from "../hooks/useParams"
import LoadingIndicator from "./LoadingIndicator"
import ProductSearchFilters, {
  DEFAULT_PRODUCT_FILTERS,
  ProductFilters,
} from "./ProductSearchFilters"
import { pluralize } from "../util/pluralize"
import ProductSearchResults, { NAME_SORT_OPTION } from "./ProductSearchResults"
import { FiltersDisclosureButton, SearchInput } from "./SearchElements"
import { withBasics } from "./withBasics"

const ProductSearchScreen = ({
  isActiveClientOrder,
}: {
  isActiveClientOrder?: boolean
}) => {
  const [params, setParams] = useParams()
  // @ts-expect-error todo
  const [query, setQuery] = useState(params.q || "")
  const [searchQuery] = useDebounce(query, 300)
  const [sort, setSort] = useState<ProductSort>(NAME_SORT_OPTION)
  const [filters, setFilters] = useState<ProductFilters>(
    DEFAULT_PRODUCT_FILTERS,
  )

  const result = useInfiniteGraphQLQuery(
    useProductSearchQuery,
    ({ pageParam }) => ({
      query: searchQuery,
      after: pageParam,
      sort,
      ...filters,
    }),
    {
      getNextPageParam: (page) => page.products.pageInfo.endCursor ?? undefined,
    },
  )

  return (
    <ActiveClientOrderContext.Provider value={isActiveClientOrder ?? false}>
      <div className="d-flex align-items-center mt-6">
        <div className="d-flex mr-auto">
          <h1 className="my-0 mr-3 f-title mb-5">Products</h1>
          {result.isFetching && <LoadingIndicator />}
        </div>
        <div>
          <a
            className="btn btn-dark"
            href="/product-groups/new"
            data-testid="new-product"
          >
            New Product
          </a>
        </div>
      </div>
      <Disclosure>
        <div className="mb-4 d-flex">
          <SearchInput
            onChange={(e) => {
              setQuery(e.currentTarget.value)
              // @ts-expect-error todo
              setParams({ q: e.currentTarget.value }, { replace: true })
            }}
            value={query}
            placeholder="Search by name"
          />
          <FiltersDisclosureButton />
        </div>

        <DisclosurePanel>
          <div className="border rounded border-light-teal position-relative">
            <div className="p-3">
              <ProductSearchFilters setFilters={setFilters} filters={filters} />
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <>
        Results:{" "}
        {result.status === "success"
          ? pluralize({
              zero: "No results",
              singular: "1 Product",
              plural: "{{count}} Products",
              count: result.data.pages[0].products.totalCount,
            })
          : "…"}
      </>

      <ProductSearchResults
        result={result}
        query={query}
        sort={sort}
        setSort={setSort}
        // @ts-expect-error todo
        setFilters={setFilters}
      />

      {result.hasNextPage && (
        <button
          onClick={() => result.fetchNextPage()}
          type="button"
          className="btn btn-primary mb-3 mt-3"
          disabled={!!result.isFetchingNextPage}
        >
          {result.isLoading ? "Loading..." : "Load more"}
        </button>
      )}
    </ActiveClientOrderContext.Provider>
  )
}

export default withBasics(ProductSearchScreen)
